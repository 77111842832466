import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';

import * as Constants from '../../helpers/constants'
import showToastNotification from '../widgets/toastify';
import { ApiHelper } from '../../helpers/apihelper';

import 'react-toastify/dist/ReactToastify.css';

const initialState = {
  credits: '',
  description: '',
  showModal: false,
  errors: {},
  isApiCallInProgress: false
}

function AddBonusModel({ getUserInfo, name, client, userData }) {
  const [state, setState] = useState(initialState)
  const id = useSelector((state) => state.user.id);
  const userName = useSelector(state => state.user.name);

  const handleCreditsChange = (e) => {
    setState(prevState => ({ ...prevState, credits: e.target.value }))
  };

  const handleDescChange = (e) => {
    setState(prevState => ({ ...prevState, description: e.target.value }))
  }

  const validateForm = () => {
    let err = {}
    if (state.credits.toString().length > 10) {
      err['credits'] = 'Please enter credits less than 10 chars'
    }
    if (Object.keys(err).length) {
      setState((prevState) => ({ ...prevState, errors: err }))
      setTimeout(() => {
        setState((prevState) => ({ ...prevState, errors: {} }))
      }, 5000);
      return false
    }
    return true
  }

  const handleSaveChanges = () => {
    if (state.isApiCallInProgress) {
      return;
    }
    if (validateForm()) {
      let requestBody = {
        client_secret: Constants.CLIENT_SECRET,
        co_user_id: userData.user_id,
        cop_user_id: userData.user_id,
        user_id: id,
        crm_user_data: {
          name: userName
        },
        created_by: id,
        credits: state.credits,
        description: state.description,
      }

      let body = _.pick(requestBody, ApiHelper(client, 'ADD_BONUS').API_BODY)

      setState(prevState => ({ ...prevState, isApiCallInProgress: true }));
      ApiHelper(client, 'ADD_BONUS').API(client, body)
        .then((response) => {
          if (response.status === Constants.CO_API.FAILED) {
            showToastNotification({
              message: 'Failed to add credits',
              isSuccess: false,
            });
          } else if (response.status === Constants.CO_API.SUCCESS) {
            getUserInfo()
            showToastNotification({
              message: 'Credits added successfully',
              isSuccess: true,
            });
            setState(initialState)
            setState((prevState) => ({
              ...prevState,
              showModal: false,
            }));
            setState(prevState => ({ ...prevState, isButtonDisabled: false }))
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          setState(prevState => ({ ...prevState, isApiCallInProgress: false }));
        });
    }
  };

  const handleShowModal = () => {
    setState(prevState => ({ ...prevState, showModal: true }));
  };

  const handleCloseModal = () => {
    setState(initialState)
  }

  return (
    <>
      <Button
        onClick={handleShowModal}
        variant='outline-primary'
        size="sm"
        className='sm-btns'
      >
        <FontAwesomeIcon icon="fa fa-plus" />
      </Button>
      <Modal
        show={state.showModal}
        onHide={handleCloseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title className='fs-6 fw-bold'>Add Bonus to {name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body-bg-color'>
          <Form>
            <div className='row'>
              <Form.Group
                className='d-flex'
              >
                <div className='col-md-3'>
                  <Form.Label className='w-100 py-2'>Add Credits:</Form.Label>
                </div>
                <div className='col-md-9'>
                  <Form.Control
                    type="number"
                    placeholder="Enter credits"
                    value={state.credits}
                    onChange={handleCreditsChange}
                  />
                  {state.errors ? <p className='text-danger'>{state.errors.credits}</p> : null}
                </div>
              </Form.Group>
            </div>
            <div className='row'>
              <Form.Group
                className='d-flex'
              >
                <div className='col-md-3'>
                  <Form.Label className='w-100 py-2'>Add Desc:</Form.Label>
                </div>
                <div className='col-md-9'>
                  <Form.Control
                    type="text"
                    placeholder="Enter Description"
                    value={state.description}
                    onChange={handleDescChange}
                    maxLength={50}
                  />
                </div>
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={handleSaveChanges}
            disabled={!state.credits || !state.description || state.isApiCallInProgress}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddBonusModel;
