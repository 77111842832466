import React, { useCallback, useEffect, useState, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import _ from 'lodash'

import * as Constants from '../../helpers/constants'
import RequestCard from './card'
import * as Helper from '../../helpers/helper'
import { usePrevious } from '../../helpers/helper'
import LoadingWrapper from '../widgets/loading'
import { ApiHelper } from '../../helpers/apihelper';
import AppConfigContext from '../app_layout/app_config_context'
import '../../static/css/prospect_requests.css'

const initialState = {
  requests: [],
  isLoading: false,
  user_email: '',
  org_id: null,
  org_name: '',
  inprogressRequests: false
}

let interval = null

const ProspectRequests = () => {
  const [state, setState] = useState(initialState)
  const previousState = usePrevious({
    user_email: state.user_email,
    org_id: state.org_id,
  })

  const location = useLocation()
  const appConfig = useContext(AppConfigContext)
  const client = appConfig.client

  const getApiParams = useCallback(() => {
    let params = { filters: {} }

    params.filters.user_email = state.user_email
    if (!params.filters.user_email) delete params.filters.user_email

    if (state.org_id) params.filters.org_id = state.org_id

    return params
  }, [state.org_id, state.user_email])

  const loadRequests = useCallback(() => {
    let requestBody = getApiParams()
    setState((prevStates) => ({ ...prevStates, isLoading: true }))

    let body = _.pick(requestBody, ApiHelper(client, 'PROSPECT_REQUESTS').API_BODY)
    ApiHelper(client, 'PROSPECT_REQUESTS').API(client, body)
      .then((res) => {
        if (res.status === Constants.CO_API.SUCCESS) {
          setState((prevStates) => ({
            ...prevStates,
            requests: res.data,
            inprogressRequests: res.data.length,
            isLoading: false,
          }))
        } else if (res.status === Constants.CO_API.FAILED) {
          setState((prevStates) => ({
            ...prevStates,
            requests: [],
            inprogressRequests: 0,
            isLoading: false,
          }))
        }
      })
      .catch((err) => {
        setState((prevStates) => ({
          ...prevStates,
          requests: [],
          inprogressRequests: 0,
          isLoading: false,
        }))
        console.log('loadRequests api erred', err)
      })
  }, [getApiParams, client])

  // did mount
  useEffect(() => {
    let params = new URLSearchParams(location.search)

    if (params) {
      if (params.get('user_email')) {
        setState((prevStates) => ({
          ...prevStates,
          user_email: decodeURIComponent(params.get('user_email')),
        }))
      }

      if (params.get('org_id')) {
        setState((prevStates) => ({
          ...prevStates,
          org_id: params.get('org_id'),
          org_name: params.get('org_name'),
        }))
      }
    }
  }, [location.search])

  useEffect(() => loadRequests(), [loadRequests])

  // did update
  useEffect(() => {
    let timer = null
    if (previousState) {
      if (
        (previousState.user_email !== state.user_email && !state.user_email) ||
        (state.user_email &&
          previousState.user_email !== state.user_email &&
          Helper.validateEmailAddress(state.user_email))
      ) {
        timer = setTimeout(() => {
          loadRequests()
        }, 500)
      }

      if (previousState.org_id !== state.org_id) {
        loadRequests()
      }
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
        timer = null
      }
    }
  }, [loadRequests, previousState, state.user_email, state.org_id])

  // polling
  useEffect(() => {
    if (state.inprogressRequests) {
      interval = setInterval(() => loadRequests(), 30000)
    }

    if (!state.inprogressRequests && interval) {
      clearInterval(interval)
      interval = null
    }

    return () => {
      if (interval) {
        clearInterval(interval)
        interval = null
      }
    }
  }, [state.inprogressRequests, loadRequests])

  return (
    <>
      <section className='content-header'>
        <h1
          className='header'
        >
          clearout&nbsp; Prospect Requests
          {state.org_name ? '/' + state.org_name : ''}
        </h1>
      </section>
      <section className='content'>
        <div className='page-container container-fluid'>
          <div className='page-content d-flex flex-column'>
            <div
              className='filters-div prospect-search-input'
            >
              <input
                type='text'
                onChange={(event) =>
                  setState((prevStates) => ({
                    ...prevStates,
                    user_email: event.target.value.trim(),
                  }))
                }
                placeholder={'Filter by Email id'}
                className={'form-control email-search prospect-search'}
                value={state.user_email}
              />
              <button
                className='btn-clearout-refresh btn btn-md'
                title='Refresh'
                onClick={() => loadRequests()}
              >
                <i className='fa fa-refresh'></i>
              </button>
            </div>
            {state.isLoading ? (
              <div className="loader-container">
                <LoadingWrapper
                  type="bars"
                  color="#6229e3"
                  height={50}
                  width={50}
                />
              </div>
            ) : null}
            <div
              className='d-flex flex-column requests-container'
            >
              {_.isEmpty(state.requests) ? (
                <h1
                  className='d-flex justify-content-center no-requests-found'
                >
                  {'No Requests Found ;('}
                </h1>
              ) : (
                _.map(state.requests, (req) => (
                  <RequestCard
                    key={req.request_id}
                    reqInfo={req}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ProspectRequests
