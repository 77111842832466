import React, { useState, useEffect, useCallback, useRef, useContext } from 'react'
import { CSVLink } from 'react-csv'
import _ from 'lodash'
import { Button } from 'react-bootstrap'

import FiltersComponent from '../widgets/filters'
import UserListTable from '../tables/user_list/user_list_table'
import CreateUserModal from '../users_list/create_user_modal'
import CustomOverlayTrigger from '../widgets/overlay_trigger'

import * as Constants from '../../helpers/constants'
import * as FiltersConstants from './../widgets/filters/constants'
import { ApiHelper } from '../../helpers/apihelper'
import tableFormatters from './../tables/user_list/helpers/user_list_formatter'
import * as UserListTableConstnats from './../tables/user_list/helpers/constant'

import AppConfigContext from '../app_layout/app_config_context'

const initialState = {
  data: [],
  columns: [],
  isLoading: false,
  searchText: '',
  dataToDownload: '',
  filename: '',
  tableDataLength: 0,
  sorting: [],
  filters: {
    date_range: {
      filter_label: 'date_range',
      value: {
        key: 'created_on',
        preset: 'ps_last_7_days_including_today'
      }
    },
    status: {
      filter_label: 'User Status',
      label: 'All',
      value: 'all'
    }
  },
}

const filterParams = (filters) => {
  let queryFilter = {
    subs: filters.subs?.value || null,
    subs_status: filters.subs_status?.value || null,
    subs_plan_type: filters.subs_plan_type?.value || null,
    status: filters.status?.value || null,
    payment_status: null,
    date_range: filters.date_range?.value || null,
    org_id: filters.org_filter?.value || null,
  }

  if (queryFilter.subs === 'paid') {
    queryFilter.payment_status = 'paid'
    queryFilter.subs = null
  }

  if (queryFilter.status === 'all') {
    queryFilter.status = null
  }
  return queryFilter
}

const UserlistPage = () => {
  const [state, setState] = useState(initialState)
  const csvLinkRef = useRef(null)
  const abortControllerRef = useRef(null) // Ref to store the controller across renders

  const appConfig = useContext(AppConfigContext)
  const client = appConfig.client

  // Cleanup effect to abort any pending requests when the component unmounts
  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort('canceled')
      }
      setState(initialState)
    }
  }, [])

  useEffect(() => {
    setState(initialState)
  }, [client])

  const getUsersListApi = useCallback((requestBody, signal = null) => {
    setState(prevState => ({ ...prevState, isLoading: true }))
    let body = _.pick(requestBody, ApiHelper(client, 'USER_LIST').API_BODY)

    ApiHelper(client, 'USER_LIST').API(client, body, signal)
      .then((response) => {
        if (response && response.status === Constants.CO_API.SUCCESS) {

          // calc table cols
          const columns = UserListTableConstnats.USER_LIST_TABLE_COLUMNS[client].map(col => {
            return {
              header: col.header,
              accessorKey: col.accessorKey,
              cell: (cell) => tableFormatters[col.cellFormatter](cell, client),
            }
          })

          setState(prevState => ({
            ...prevState,
            columns,
            data: response.data.data,
            tableDataLength: Math.ceil(response.data.count / body.limit)
          }))
        }
      })
      .catch((error) => {
        if (error.name !== 'AbortError') {
          console.error(error)
        }
      })
      .finally(() => {
        setState(prevState => ({ ...prevState, isLoading: false }))
      })
  }, [client])

  const getClearoutUsers = useCallback((skip, limit = 50, searchText, filters, sorting) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort('canceled')
    }

    const controller = new AbortController()
    abortControllerRef.current = controller

    let sortingData = { created_on: 'desc' }

    if (sorting && sorting.length > 0) {
      sortingData = { [sorting[0].id]: sorting[0].desc ? 'desc' : 'asc' }
    }

    let body = {
      skip: skip || 0,
      limit: limit || 50,
      search: searchText || state.searchText,
      sort: sortingData || state.sorting,
      filters: { ...filterParams(state.filters) },
      client_secret: Constants.CLIENT_SECRET,
    }

    getUsersListApi(body, controller.signal)
  }, [state.searchText, state.sorting, state.filters, getUsersListApi])

  useEffect(() => {
    getClearoutUsers()
  }, [getClearoutUsers])

  useEffect(() => {
    if (csvLinkRef.current && state.dataToDownload) {
      csvLinkRef.current.link.click()
    }
  }, [state.dataToDownload])

  const getClearoutUsersForDownload = (skip, limit, searchText, filters, sorting) => {
    let requestBody = {
      skip: skip,
      limit: limit,
      search: searchText,
      sort: sorting,
      filters: filterParams(filters),
      download: true,
      client_secret: Constants.CLIENT_SECRET
    }

    let body = _.pick(requestBody, ApiHelper(client, 'USER_LIST_DOWNLOAD').API_BODY)

    // Setting the filename based on date range
    if (body.filters.date_range && body.filters.date_range.preset) {
      setState(prevState => ({
        ...prevState,
        filename: (`${client}_users_${body.filters.date_range.preset}_${new Date().getTime()}.csv`)
      }))
    } else {
      setState(prevState => ({
        ...prevState,
        filename: (`${client}_users_${new Date().getTime()}.csv`)
      }))
    }

    ApiHelper(client, 'USER_LIST_DOWNLOAD').API(client, body)
      .then((res) => {
        setState(prevState => ({ ...prevState, dataToDownload: res }))
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const clearSearch = () => {
    setState(prevState => ({ ...prevState, searchText: '' }))
  }

  const onSearch = (event) => {
    setState(prevState => ({ ...prevState, searchText: event.target.value }))
  }

  const onSetFilter = (filters) => {
    setState(prevState => ({ ...prevState, filters: { ...filters } }))
  }

  const clientDisplayName = Constants.CLIENTS[client.toUpperCase()].DISPLAY_NAME
  const clientDownloadName = Constants.CLIENTS[client.toUpperCase()].DOWNLOAD_NAME

  return (
    <div className='overflow-auto'>
      <div className='d-flex justify-content-between'>
        <h1 className='m-0 header'>
          {clientDisplayName} Users
        </h1>
        <div className='mb-2'>
          <CSVLink
            data={state.dataToDownload}
            filename={state.filename}
            ref={csvLinkRef}
          >
          </CSVLink>
          <CustomOverlayTrigger
            content={clientDownloadName}
            placement="auto-start"
          >
            <Button
              className="user-data-download"
              variant='outline-primary'
              onClick={() => getClearoutUsersForDownload(state.skip, 10000, state.searchText, state.filters, { created_on: 'desc' })}
              disabled={state.data.length <= 0}
            >
              <i className="fa fa-download"></i>
            </Button>
          </CustomOverlayTrigger>
          <CreateUserModal
            getClearoutUsers={getClearoutUsers}
            client={client}
          />
        </div>
      </div>
      <div>
        <FiltersComponent
          onSetFilters={onSetFilter}
          filterState={state.filters}
          onSearch={onSearch}
          clearSearch={clearSearch}
          searchValue={state.searchText}
          filters={FiltersConstants.USER_LIST_PAGE[client].filters}
          filtersToInclude={FiltersConstants.FILTERS_TO_INCLUDE[client].USER_LIST_PAGE}
          skipFilter={['date_range', 'org_filter']}
          resetFilters={FiltersConstants.USER_LIST_PAGE[client].onResetFilters}
          client={client}
        />
      </div>
      <div>
        <UserListTable
          columns={state.columns}
          getClearoutUsers={getClearoutUsers}
          data={state.data}
          isLoading={state.isLoading}
          sorting={state.sorting}
          filterParams={filterParams}
          searchText={state.searchText}
          filters={state.filters}
          tableDataLength={state.tableDataLength}
        />
      </div>
    </div>
  )
}

export default UserlistPage
