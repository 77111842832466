import React, { useState, useEffect, useCallback } from 'react';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';  // Import the plugin
import { Chart as ChartJS, registerables } from 'chart.js';
import * as Constants from '../../../../helpers/constants';
import { PIECHART_RESULT_ORDER } from '../../helpers/constants';

// Register Chart.js components and plugins
ChartJS.register(...registerables, ChartDataLabels);

const PieChart = ({
  service,
  ...props
}) => {
  const [graphData, setGraphData] = useState({
    labels: PIECHART_RESULT_ORDER,
    datasets: [{
      data: [1, 1, 1, 1],
      backgroundColor: Constants.GRAPH_COLORS,
      hoverBackgroundColor: Constants.GRAPH_COLORS
    }]
  });

  const options = {
    animation: false,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const label = tooltipItem.label || '';
            return label;
          },
          afterLabel: function (tooltipItem) {
            const total = props.graphData && props.graphData.total ? props.graphData.total : 1;
            const value = tooltipItem.raw;
            const percent = Math.round(((value / total) * 100));
            return value + ' (' + percent + '%)';
          }
        }
      },
      datalabels: {
        color: '#fff',  // Set label color
        formatter: (value) => {
          // Display the value only if it's greater than 0
          return value > 0 ? value : null;
        },
        font: {
          weight: 'bold',
        },
      },
    }
  };

  const setGraphDataFromProps = useCallback((graphData) => {
    let chartData = {};
    chartData.labels = PIECHART_RESULT_ORDER[service.NAME].map(result => {
      return Constants.RESULTS[result].display_name;
    });
    chartData.data = PIECHART_RESULT_ORDER[service.NAME].map(result => {
      return graphData[result]?.value || 0;
    });
    return chartData;
  }, [service.NAME]);

  useEffect(() => {
    if (props.graphData && !props.isGraphDataLoading) {
      const data = setGraphDataFromProps(props.graphData);
      setGraphData({
        labels: data.labels,
        datasets: [{
          data: data.data,
          backgroundColor: Constants.GRAPH_COLORS,
          hoverBackgroundColor: Constants.GRAPH_COLORS
        }]
      });
    }
  }, [props.graphData, props.isGraphDataLoading, setGraphDataFromProps]);

  // Check if the data is empty or all values are zero
  const isChartEmpty = graphData.datasets[0]?.data.every(value => value === 0);

  return (
    <div
      style={{ height: '210px', textAlign: 'center' }}
      className='d-flex justify-content-center align-items-center'
    >
      {isChartEmpty ? (
        <p>No Data Available</p>
      ) : (
        <Pie
          data={graphData}
          height={210}
          width={210}
          options={options}
        />
      )}
    </div>
  );
};

export default PieChart;
