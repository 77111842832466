import { ajax } from './helper'

class ApiService {
  async login(body) {
    const loginUrl = 'api/v1/' + 'user/login'
    return await ajax(loginUrl, 'POST', body)
  }

  async getUserList(client, body, signal) {
    const userListUrl = 'api/v1/' + client + '/user_list'
    return await ajax(userListUrl, 'POST', body, true, signal)
  }

  async clearoutGraphInsights(client, body) {
    const clearoutGraphInsightsUrl = 'api/v1/' + client + '/insights/graph'
    return await ajax(clearoutGraphInsightsUrl, 'POST', body)
  }

  async getClearoutUsersActivities(client, body, cancelToken) {
    const getClearoutUsersListUrl = 'api/v1/' + client + '/user_activities'
    return await ajax(getClearoutUsersListUrl, 'POST', body, cancelToken)
  }

  async createClearoutUser(client, body) {
    const createClearoutUserUrl = 'api/v1/' + client + '/createuser'
    return await ajax(createClearoutUserUrl, 'POST', body)
  }

  async getOrganizationNames(client, body) {
    const getOrganizationListsUrl = 'api/v1/' + client + '/organization/names'
    return await ajax(getOrganizationListsUrl, 'POST', body)
  }

  async getCleaoutUserInfo(client, body) {
    const getClearoutUserInfo = 'api/v1/' + client + '/user_info'
    return await ajax(getClearoutUserInfo, 'POST', body)
  }

  async clearoutUpdateUserEmail(client, body) {
    const clearoutUpdateTimezoneUrl = 'api/v1/' + client + '/email_edit'
    return await ajax(clearoutUpdateTimezoneUrl, 'POST', body)
  }

  async clearoutUpdateTimezone(client, body) {
    const clearoutUpdateTimezoneUrl = 'api/v1/' + client + '/update_timezone'
    return await ajax(clearoutUpdateTimezoneUrl, 'POST', body)
  }

  async giveBonusClearoutUser(client, body) {
    const giveBonusClearoutUserUrl = 'api/v1/' + client + '/bonus'
    return await ajax(giveBonusClearoutUserUrl, 'POST', body)
  }

  async resetBonusClearoutUser(client, body) {
    const resetBonusClearoutUserUrl = 'api/v1/' + client + '/credits/reset'
    return await ajax(resetBonusClearoutUserUrl, 'POST', body)
  }

  async toggleRadar(client, body) {
    const changeRadarStatus = 'api/v1/' + client + '/user/toggle_radar'
    return await ajax(changeRadarStatus, 'POST', body)
  }

  async getClearoutUserProfileAdditionalInfo(client, body) {
    const getClearoutUserProfileAdditionalInfo = 'api/v1/' + client + '/user_profile_additional_info'
    return await ajax(getClearoutUserProfileAdditionalInfo, 'POST', body)
  }

  async updateRateLimitIndividual(client, body) {
    const updateRateLimitIndividualUrl = 'api/v1/' + client + '/user/update_api_ratelimit'
    return await ajax(updateRateLimitIndividualUrl, 'POST', body)
  }

  async removeRateLimitIndividual(client, body) {
    const removeRateLimitIndividualUrl = 'api/v1/' + client + '/user/remove_api_ratelimit'
    return await ajax(removeRateLimitIndividualUrl, 'POST', body)
  }

  async clearoutAddDailyLimit(client, body) {
    const clearoutAddDailyLimitUrl = 'api/v1/' + client + '/set_daily_limit'
    return await ajax(clearoutAddDailyLimitUrl, 'POST', body)
  }

  async clearoutUsersRemoveDailyLimit(client, body) {
    const clearoutUsersRemoveDailyLimitUrl = 'api/v1/' + client + '/remove_daily_limit'
    return await ajax(clearoutUsersRemoveDailyLimitUrl, 'POST', body)
  }

  async clearoutUpdateDailyLimit(client, body) {
    const clearoutUpdateDailyLimitUrl = 'api/v1/' + client + '/update_daily_limit'
    return await ajax(clearoutUpdateDailyLimitUrl, 'POST', body)
  }

  async listInvoices(client, co_user_id) {
    const listInvoicesUrl = 'api/v1/' + client + '/receipts'
    return await ajax(listInvoicesUrl, 'POST', co_user_id)
  }

  async clearoutMarkAsPaid(client, body) {
    const clearoutMarkAsPaidUrl = 'api/v1/' + client + '/mark_as_paid'
    return await ajax(clearoutMarkAsPaidUrl, 'POST', body)
  }

  async logoutAllSessionOfClearoutUser(client, body) {
    const logoutAllSessionOfClearoutUserUrl = 'api/v1/' + client + '/logout'
    return await ajax(logoutAllSessionOfClearoutUserUrl, 'POST', body)
  }

  async blockClearoutUser(client, body) {
    const blockUserApiUrl = 'api/v1/' + client + '/user/block'
    return await ajax(blockUserApiUrl, 'POST', body)
  }

  async unblockClearoutUser(client, body) {
    const unblockUserApiUrl = 'api/v1/' + client + '/user/unblock'
    return await ajax(unblockUserApiUrl, 'POST', body)
  }

  async deleteClearoutUser(client, body) {
    const deleteUserApiUrl = 'api/v1/' + client + '/user/delete'
    return await ajax(deleteUserApiUrl, 'POST', body)
  }

  async resetTokens(client, body) {
    const resetTokensUrl = 'api/v1/' + client + '/reset_tokens'
    return await ajax(resetTokensUrl, 'POST', body)
  }

  async getClearoutUsersList(client, body, signal) {
    const getClearoutUsersListUrl = 'api/v1/' + client + '/user_list'
    return await ajax(getClearoutUsersListUrl, 'POST', body, true, signal)
  }

  async removeEmailFromCache(client, queryParams) {
    const removeEmailFromCacheUrl = 'api/v1/' + client + '/email_verify/remove_email_from_cache'
    const url = `${removeEmailFromCacheUrl}?${new URLSearchParams(queryParams).toString()}`
    return await ajax(url, 'DELETE')
  }

  async updateDisposableDomains(client, body) {
    const updatedisposable = 'api/v1/' + client + '/sysprop/update_disposable_domain'
    return await ajax(updatedisposable, 'PUT', body)
  }

  async overAllUsageReports(client, queryParams) {
    const usageReportsUrl = 'api/v1/' + client + '/report/all'
    const url = `${usageReportsUrl}?${new URLSearchParams(queryParams).toString()}`
    return await ajax(url, 'GET')
  }

  async emailVerifyReports(client, queryParams) {
    const allUsersEmailVerifyList = 'api/v1/' + client + '/report/email_verify'
    const url = `${allUsersEmailVerifyList}?${new URLSearchParams(queryParams).toString()}`
    return await ajax(url, 'GET')
  }

  async emailFinderReports(client, queryParams) {
    const allUsersEmailFinderList = 'api/v1/' + client + '/report/email_finder'
    const url = `${allUsersEmailFinderList}?${new URLSearchParams(queryParams).toString()}`
    return await ajax(url, 'GET')
  }

  async prospectReports(client, queryParams) {
    const allUsersProspectList = 'api/v1/' + client + '/report/prospect'
    const url = `${allUsersProspectList}?${new URLSearchParams(queryParams).toString()}`
    return await ajax(url, 'GET')
  }

  async accountStatusUpdateClearoutUser(client, body) {
    const userAccountStatusUpdateUrl = 'api/v1/' + client + '/user/account_status_update'
    return await ajax(userAccountStatusUpdateUrl, 'POST', body)
  }

  async getClearoutUserStatuses(client, body) {
    const coUserStatusApiUrl = 'api/v1/' + client + '/user/statuses'
    return await ajax(coUserStatusApiUrl, 'POST', body)
  }

  async getCoProductList(client, queryParams) {
    const getCoProductListUrl = 'api/v1/' + client + '/product/list'
    let query = new URLSearchParams(queryParams).toString()

    const url = getCoProductListUrl + '?' + query
    return await ajax(url, 'GET')
  }

  async getCoAddonProductInfo(client, queryParams) {
    const getCoAddonProductInfoUrl = 'api/v1/' + client + '/product/addon_info'
    let query = new URLSearchParams(queryParams).toString()

    const url = getCoAddonProductInfoUrl + '?' + query
    return await ajax(url, 'GET')
  }

  async getProduct(client, queryParams) {
    const getProductUrl = 'api/v1/' + client + '/product/view'
    let query = new URLSearchParams(queryParams)
    const url = getProductUrl + '?' + query
    return await ajax(url, 'GET')
  }

  async createProduct(client, body) {
    const createProductUrl = 'api/v1/' + client + '/product/create'
    return await ajax(createProductUrl, 'POST', body)
  }

  async udpateUserPaymentSettings(client, body) {
    const udpateUserPaymentSettingsUrl = 'api/v1/' + client + '/user/payment_settings_update'
    return await ajax(udpateUserPaymentSettingsUrl, 'PATCH', body)
  }

  async attachProduct(client, body) {
    const attachProductUrl = 'api/v1/' + client + '/product/attach'
    return await ajax(attachProductUrl, 'POST', body)
  }

  async detachProduct(client, body) {
    const detachProductUrl = 'api/v1/' + client + '/product/detach'
    return await ajax(detachProductUrl, 'POST', body)
  }

  async getOrganizationLists(client, body, signal) {
    const getOrganizationListsUrl = 'api/v1/' + client + '/organization/list'
    return await ajax(getOrganizationListsUrl, 'POST', body, true, signal)
  }

  async getOrganizationInfo(client, body) {
    const getOrganizationInfoUrl = 'api/v1/' + client + '/organization_info'
    return await ajax(getOrganizationInfoUrl, 'POST', body)
  }

  async giveBonusClearoutOrg(client, body) {
    const giveBonusClearoutOrgUrl = 'api/v1/' + client + '/organization_bonus'
    return await ajax(giveBonusClearoutOrgUrl, 'POST', body)
  }

  async getUsersPlansAndActiveSubscriptions(client, queryParams) {
    const getProductUrl = 'api/v1/' + client + '/plan/list'
    let query = new URLSearchParams(queryParams)
    const url = getProductUrl + '?' + query
    return await ajax(url, 'GET')
  }

  async resetClearoutOrgCredits(client, body) {
    const resetClearoutOrgCreditsUrl =
      'api/v1/' + client + '/organization/credits/reset'
    return await ajax(resetClearoutOrgCreditsUrl, 'POST', body)
  }

  async updateRateLimitOrg(client, body) {
    const updateRateLimitOrgUrl = 'api/v1/' + client + '/organization/update_api_ratelimit'
    return await ajax(updateRateLimitOrgUrl, 'POST', body)
  }

  async removeRateLimitOrg(client, body) {
    const removeRateLimitOrgUrl = 'api/v1/' + client + '/organization/remove_api_ratelimit'
    return await ajax(removeRateLimitOrgUrl, 'POST', body)
  }

  async updateOrgDailyLimit(client, body) {
    const updateOrgDailyLimitUrl =
      'api/v1/' + client + '/organization/update_daily_limit'
    return await ajax(updateOrgDailyLimitUrl, 'POST', body)
  }

  async clearoutOrgAddDailyLimit(client, body) {
    const clearoutOrgAddDailyLimitUrl =
      'api/v1/' + client + '/organization/update_allowed_daily_limit'
    return await ajax(clearoutOrgAddDailyLimitUrl, 'POST', body)
  }

  async updateOrgAllocateSeat(client, body) {
    const updateOrgDailyLimitUrl =
      'api/v1/' + client + '/organization/update_member_seat_limit'
    return await ajax(updateOrgDailyLimitUrl, 'POST', body)
  }

  async getSystemRadarSettings(client, queryParams) {
    const getSystemRadarSettingsUrl = 'api/v1/' + client + '/radar/settings_list'
    const url = `${getSystemRadarSettingsUrl}?${new URLSearchParams(queryParams).toString()}`
    return await ajax(url, 'GET')
  }

  async updateSystemRadarSettings(client, body) {
    const updateSystemRadarSettingsUrl = 'api/v1/' + client + '/radar/settings_update'
    return await ajax(updateSystemRadarSettingsUrl, 'PUT', body)
  }

  async udpateUserDefaultPaymentAccount(client, body) {
    const udpateUserDefaultPaymentAccountgsUrl = 'api/v1/' + client + '/user/payment_account_update'
    return await ajax(udpateUserDefaultPaymentAccountgsUrl, 'PATCH', body)
  }


  async getAbuseListWrtCountry(client, body) {
    const addAbuseListsUrl = 'api/v1/' + client + '/abuse_list/country'
    return await ajax(addAbuseListsUrl, 'POST', body)
  }

  async addAbuseListEntry(client, body) {
    const addAbuseListsUrl = 'api/v1/' + client + '/abuse_list/add'
    return await ajax(addAbuseListsUrl, 'POST', body)
  }

  async signUpAbuseLimiter(client, body) {
    const signUpAbuseLimiterUrl = 'api/v1/' + client + '/signup_abuse_limiter'
    return await ajax(signUpAbuseLimiterUrl, 'POST', body)
  }

  async getAbuseList(client, body, signal) {
    const getAbuseListsUrl = 'api/v1/' + client + '/abuse_list'
    return await ajax(getAbuseListsUrl, 'POST', body, true, signal)
  }

  async listCoupons(client, body, signal) {
    const listCouponsUrl = 'api/v1/' + client + '/coupon/list'
    return await ajax(listCouponsUrl, 'POST', body, true, signal)
  }

  async createCoupon(client, body) {
    const createCouponUrl = 'api/v1/' + client + '/coupon/create'
    return await ajax(createCouponUrl, 'POST', body)
  }

  async editCoupon(client, body) {
    const editCouponUrl = 'api/v1/' + client + '/coupon/edit'
    return await ajax(editCouponUrl, 'POST', body)
  }

  async updateAbuseListEntry(client, body) {
    const updateAbuseListsUrl = 'api/v1/' + client + '/abuse_list/update'
    return await ajax(updateAbuseListsUrl, 'POST', body)
  }

  async getClearoutAbuseActivityLogs(client, body, signal) {
    const getClearoutAbuseActivityUrl = 'api/v1/' + client + '/abuse_activity'
    return await ajax(getClearoutAbuseActivityUrl, 'POST', body, true, signal)
  }

  async getClearoutAbuseUpdateLogs(client, body, signal) {
    const getClearoutAbuseUpdateActivityUrl = 'api/v1/' + client + '/abuse_update_activity'
    return await ajax(getClearoutAbuseUpdateActivityUrl, 'POST', body, true, signal)
  }

  async getUserSubscriptions(client, queryParams) {
    const getUserSubscriptionsUrl = 'api/v1/' + client + '/user_subscription/list'
    let query = new URLSearchParams(queryParams).toString()

    const url = getUserSubscriptionsUrl + '?' + query
    return await ajax(url, 'GET')
  }

  async getClearoutEVLists(client, body, cancelToken) {
    const getClearoutListsUrl = `api/v1/${client}/list`;
    return await ajax(getClearoutListsUrl, 'POST', body, true, cancelToken);
  }

  async downloadEVListInputFile(client, inputFileKey) {
    const downloadInputFile = `api/v1/${client}/download/input_file`;
    return await ajax(downloadInputFile, 'POST', inputFileKey);
  }

  async getClearoutOrgUsersList(client, body, signal) {
    const getClearoutUsersListUrl = `api/v1/${client}/org_users/list`;
    return await ajax(getClearoutUsersListUrl, 'POST', body, true, signal);
  }

  async downloadresultForEVList(client, body) {
    const downloadresultForEVListUrl = `api/v1/${client}/download/result`;
    return await ajax(downloadresultForEVListUrl, 'POST', body);
  }

  async getClearoutProspectRequests(client, body) {
    const getProspectRequestsUrl = `api/v1/${client}/prospect/requests`;
    return await ajax(getProspectRequestsUrl, 'POST', body, true, null)
  }
  async getEmailFinderLists(client, body, signal) {
    const getClearoutEmailFinderListsUrl = `api/v1/${client}/email_finder/list`;
    return await ajax(getClearoutEmailFinderListsUrl, 'POST', body, true, signal);
  }

  async downloadEmailFinderInputFile(client, inputFileKey) {
    const downloadEmailFinderInputFile = `api/v1/${client}/download/email_finder_input_file`;
    return await ajax(downloadEmailFinderInputFile, 'POST', inputFileKey);
  }

  async downloadEmailFinderResultFile(client, body) {
    const downloadEmailFinderResultFile = `api/v1/${client}/download/email_finder_result`;
    return await ajax(downloadEmailFinderResultFile, 'POST', body);
  }

  async getProspectLists(client, body, signal) {
    const getProspectListUrl = `api/v1/${client}/prospect/list`
    return await ajax(getProspectListUrl, 'POST', body, true, signal)
  }

  async downloadProspectResultFile(client, body) {
    const getProspectRequestsUrl = `api/v1/${client}/prospect/export`
    return await ajax(getProspectRequestsUrl, 'POST', body)
  }


  //cop api's
  async getClearoutPhoneUsersList(client, body, signal) {
    const getClearoutUsersListUrl = CLEAROUT_PHONE_BASE_URL + 'v1/user/list'
    return await ajax(
      getClearoutUsersListUrl,
      'POST',
      body,
      false,
      signal,
      false
    )
  }

  async createClearoutPhoneUser(client, body) {
    const createClearoutPhoneUserUrl = CLEAROUT_PHONE_BASE_URL + 'v1/user/createuser'
    return await ajax(createClearoutPhoneUserUrl, 'POST', body, false, null, false)
  }

  async getCopUserInfo(client, body) {
    const getClearoutUserInfo = CLEAROUT_PHONE_BASE_URL + 'v1/user/info'
    return await ajax(
      getClearoutUserInfo,
      'POST',
      body,
      false,
      null,
      false
    )
  }

  async getClearoutPhoneUserProfileAdditionalInfo(client, body) {
    const createClearoutPhoneUserUrl = CLEAROUT_PHONE_BASE_URL + 'v1/user/user_profile_additional_info'
    return await ajax(createClearoutPhoneUserUrl, 'POST', body, false, null, false)
  }

  async giveBonusCopUser(client, body) {
    const giveBonusCopUserUrl = CLEAROUT_PHONE_BASE_URL + 'v1/user/bonus'
    return await ajax(
      giveBonusCopUserUrl,
      'POST',
      body,
      false,
      null,
      false
    )
  }

  async resetBonusCopUser(client, body) {
    const resetBonusCopUserUrl =
      CLEAROUT_PHONE_BASE_URL + 'v1/user/credits/reset'
    return await ajax(
      resetBonusCopUserUrl,
      'POST',
      body,
      false,
      null,
      false
    )
  }

  async clearoutPhoneUpdateTimezone(client, body) {
    const clearoutUpdateTimezoneUrl =
      CLEAROUT_PHONE_BASE_URL + 'v1/user/timezone/edit'
    return await ajax(
      clearoutUpdateTimezoneUrl,
      'POST',
      body,
      false,
      null,
      false
    )
  }

  async copResetTokens(client, body) {
    const resetTokensUrl = CLEAROUT_PHONE_BASE_URL + 'v1/user/reset_tokens'
    return await ajax(resetTokensUrl, 'POST', body, false, null, false)
  }

  async logoutCopAllSessionOfClearoutUser(client, body) {
    const logoutAllSessionOfClearoutUserUrl =
      CLEAROUT_PHONE_BASE_URL + 'v1/user/sessions/deactivate'
    return await ajax(
      logoutAllSessionOfClearoutUserUrl,
      'POST',
      body,
      false,
      null,
      false
    )
  }

  async clearoutPhoneAddDailyLimit(client, body) {
    const clearoutAddDailyLimitUrl =
      CLEAROUT_PHONE_BASE_URL + 'v1/user/set_daily_limit'
    return await ajax(
      clearoutAddDailyLimitUrl,
      'POST',
      body,
      false,
      null,
      false
    )
  }

  async clearoutPhoneUsersRemoveDailyLimit(client, body) {
    const clearoutUsersRemoveDailyLimitUrl =
      CLEAROUT_PHONE_BASE_URL + 'v1/user/remove_daily_limit'
    return await ajax(
      clearoutUsersRemoveDailyLimitUrl,
      'POST',
      body,
      false,
      null,
      false
    )
  }

  async clearoutPhoneUpdateDailyLimit(client, body) {
    const clearoutUpdateDailyLimitUrl =
      CLEAROUT_PHONE_BASE_URL + 'v1/user/update_daily_limit'
    return await ajax(
      clearoutUpdateDailyLimitUrl,
      'POST',
      body,
      false,
      null,
      false
    )
  }

  async updateRateLimitForCOPIndividual(client, body) {
    const updateRateLimitForCOPIndividualUrl =
      CLEAROUT_PHONE_BASE_URL + 'v1/crm/update_api_ratelimit'
    return await ajax(updateRateLimitForCOPIndividualUrl, 'POST', body, false, null, false)
  }

  async removeRateLimitForCOPIndividual(client, body) {
    const removeRateLimitForCOPIndividualUrl =
      CLEAROUT_PHONE_BASE_URL + 'v1/crm/remove_api_ratelimit'
    return await ajax(removeRateLimitForCOPIndividualUrl, 'POST', body, false, null, false)
  }

  async listCopInvoices(client, body) {
    const listInvoicesUrl = CLEAROUT_PHONE_BASE_URL + 'v1/user/receipts'
    return await ajax(listInvoicesUrl, 'POST', body, false, null, false)
  }

  async clearoutPhoneMarkAsPaid(client, body) {
    const clearoutPhoneMarkAsPaidUrl =
      CLEAROUT_PHONE_BASE_URL + 'v1/user/mark_as_paid'
    return await ajax(
      clearoutPhoneMarkAsPaidUrl,
      'POST',
      body,
      false,
      null,
      false
    )
  }
  async clearoutPhoneUpdateUserEmail(client, body) {
    const clearoutPhoneUpdateEmailUrl =
      CLEAROUT_PHONE_BASE_URL + 'v1/crm/email_edit'
    return await ajax(
      clearoutPhoneUpdateEmailUrl,
      'POST',
      body,
      false,
      null,
      false
    )
  }

  async blockClearoutPhoneUser(client, body) {
    const blockClearoutPhoneUserUrl =
      CLEAROUT_PHONE_BASE_URL + 'v1/user/block_user'
    return await ajax(
      blockClearoutPhoneUserUrl,
      'POST',
      body,
      false,
      null,
      false
    )
  }

  async unBlockClearoutPhoneUser(client, body) {
    const unBlockClearoutPhoneUserUrl =
      CLEAROUT_PHONE_BASE_URL + 'v1/user/unblock_user'
    return await ajax(
      unBlockClearoutPhoneUserUrl,
      'POST',
      body,
      false,
      null,
      false
    )
  }

  async deleteClearoutPhoneUser(client, body) {
    const deleteClearoutPhoneUserUrl =
      CLEAROUT_PHONE_BASE_URL + 'v1/user/user_delete'
    return await ajax(
      deleteClearoutPhoneUserUrl,
      'POST',
      body,
      false,
      null,
      false
    )
  }

  async listCopCoupons(client, body, signal) {
    const listCouponsUrl = CLEAROUT_PHONE_BASE_URL + 'v1/coupon/list'
    return await ajax(listCouponsUrl, 'POST', body, false, signal, false)
  }

  async createCopCoupon(client, body) {
    const createCouponUrl = CLEAROUT_PHONE_BASE_URL + 'v1/coupon/create'
    return await ajax(createCouponUrl, 'POST', body, false, null, false)
  }

  async editCopCoupon(client, body) {
    const editCouponUrl = CLEAROUT_PHONE_BASE_URL + 'v1/coupon/edit'
    return await ajax(editCouponUrl, 'POST', body, false, null, false)
  }

  async getCopAbuseList(client, body, signal) {
    const getAbuseListsUrl = CLEAROUT_PHONE_BASE_URL + 'v1/crm/abuse_list'
    return await ajax(
      getAbuseListsUrl,
      'POST',
      body,
      false,
      signal,
      false
    )
  }

  async addCopAbuseListEntry(client, body) {
    const addAbuseListsUrl = CLEAROUT_PHONE_BASE_URL + 'v1/crm/abuse_list/add'
    return await ajax(
      addAbuseListsUrl,
      'POST',
      body,
      false,
      null,
      false
    )
  }

  async getCopAbuseListWrtCountry(client, body) {
    const addAbuseListsUrl = CLEAROUT_PHONE_BASE_URL + 'v1/crm/abuse_list/country'
    return await ajax(
      addAbuseListsUrl,
      'POST',
      body,
      false,
      null,
      false
    )
  }

  async copSignUpAbuseLimiter(client, body) {
    const signUpAbuseLimiterUrl =
      CLEAROUT_PHONE_BASE_URL + 'v1/crm/signup_abuse_limiter'
    return await ajax(
      signUpAbuseLimiterUrl,
      'POST',
      body,
      false,
      null,
      false
    )
  }

  async updateCopAbuseListEntry(client, body) {
    const updateAbuseListsUrl =
      CLEAROUT_PHONE_BASE_URL + 'v1/crm/abuse_list/update'
    return await ajax(
      updateAbuseListsUrl,
      'POST',
      body,
      false,
      null,
      false
    )
  }

  async getClearoutPhoneAbuseActivityLogs(client, body, signal) {
    const updateAbuseListsUrl =
      CLEAROUT_PHONE_BASE_URL + 'v1/crm/abuse_activity'
    return await ajax(
      updateAbuseListsUrl,
      'POST',
      body,
      false,
      signal,
      false
    )
  }

  async getClearoutPhoneAbuseUpdateLogs(client, body, signal) {
    const updateAbuseActivityUrl =
      CLEAROUT_PHONE_BASE_URL + 'v1/crm/abuse_update_activity'
    return await ajax(
      updateAbuseActivityUrl,
      'POST',
      body,
      false,
      signal,
      false
    )
  }

  async getCopUsersActivities(client, body, signal) {
    const getCopUsersActivitiesUrl =
      CLEAROUT_PHONE_BASE_URL + 'v1/crm/user_activities'
    return await ajax(
      getCopUsersActivitiesUrl,
      'POST',
      body,
      false,
      signal,
      false
    )
  }

  async clearoutPhoneUserSubsList(body, signal) {
    const clearoutPhoneUserSubsListUrl =
      CLEAROUT_PHONE_BASE_URL + 'v1/crm/subscription/list';
    return await ajax(
      clearoutPhoneUserSubsListUrl,
      'POST',
      body,
      false,
      signal,
      false
    );
  }

  async getCopLists(client, body, signal) {
    const getCopListsUrl = CLEAROUT_PHONE_BASE_URL + 'v1/user/added_list';
    return await ajax(getCopListsUrl, 'POST', body, false, signal, false);
  }

  async downloadCopInputFile(client, body, signal) {
    const downloadResultUrl =
      CLEAROUT_PHONE_BASE_URL + 'v1/user/download/input_file';
    return await ajax(
      downloadResultUrl,
      'POST',
      body,
      false,
      signal,
      false
    );
  }

  async downloadresultForPhoneClearoutList(client, body, signal) {
    const downloadresultForClearoutListUrl =
      CLEAROUT_PHONE_BASE_URL + 'v1/user/download/result';
    return await ajax(
      downloadresultForClearoutListUrl,
      'POST',
      body,
      false,
      signal,
      false
    );
  }

}

export default new ApiService()