import React, { useState } from 'react'

import PaymentAccountModel from './payment_account_modal'

const initialState = {
  isPaymentAccountModelOpen: false
}

function PaymentAccount({ userData, getUserInfo, client }) {
  const [state, setState] = useState(initialState)

  const onClickPaymentAccount = () => {
    setState((prevState) => ({
      ...prevState,
      isPaymentAccountModelOpen: !state.isPaymentAccountModelOpen
    }))
  }

  const onClosePaymentAccountModel = () => {
    setState(initialState)
  }

  return (
    <>
      <p
        onClick={onClickPaymentAccount}
        className='m-0 text-wrap cursor-pointer dropdown-item'
      >
        Payment Account
      </p>
      {
        state.isPaymentAccountModelOpen
          ? <PaymentAccountModel
            showModal={state.isPaymentAccountModelOpen}
            closeModal={onClosePaymentAccountModel}
            userData={userData}
            getUserInfo={getUserInfo}
            client={client}
          />
          : null
      }
    </>
  )
}

export default PaymentAccount
