import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import showToastNotification from '../widgets/toastify';
import * as Constants from '../../helpers/constants'
import { ApiHelper } from '../../helpers/apihelper';

const initialState = {
  showModal: false,
  domain: '',
  ip: '',
  email: '',
  domainMessage: '',
  ipMessage: '',
  emailMessage: '',
  errors: {},
  activeTab: 'Domain/IP',
}

const SignUpAbuseLimiter = ({ client }) => {
  const [state, setState] = useState(initialState)
  const id = useSelector((state) => state.user.id)
  const userName = useSelector(state => state.user.name)

  const validateForm = () => {
    let err = {}
    if (state.activeTab === 'Domain/IP') {
      if (!state.domain.trim() && !state.ip.trim()) {
        err['domain'] = 'Please enter a valid domain'
        err['ip'] = 'Please enter a valid IP'
      }
    } else if (state.activeTab === 'Email') {
      if (!state.email.trim()) {
        err['email'] = 'Please enter a valid email'
      }
    }
    if (Object.keys(err).length) {
      setState((prevState) => ({ ...prevState, errors: err }))
      setTimeout(() => {
        setState((prevState) => ({ ...prevState, errors: {} }))
      }, 2000);
      return false
    }
    return true
  }

  const handleSaveChanges = (param) => () => {
    if (validateForm()) {
      let requestBody = {
        action: param,
        user_id: id,
        crm_user_data: {
          user_id: id,
          name: userName
        },
        client_secret: Constants.CLIENT_SECRET,
      }

      if (state.activeTab === 'Domain/IP') {
        requestBody = {
          ...requestBody,
          domain: state.domain,
          ip: state.ip,
        }
      } else if (state.activeTab === 'Email') {
        requestBody = {
          ...requestBody,
          email: state.email,
        }
      }

      let body = _.pick(requestBody, ApiHelper(client, 'SIGN_UP_ABUSE_LIMITTER').API_BODY)
      ApiHelper(client, 'SIGN_UP_ABUSE_LIMITTER').API(client, body)
        .then((response) => {
          if (response.status === Constants.CO_API.FAILED) {
            showToastNotification({
              message: 'failed',
              isSuccess: false,
            });
          } else if (response.status === Constants.CO_API.SUCCESS) {
            setState(prevState => ({
              ...prevState,
              domainMessage: response.data.domain?.status,
              ipMessage: response.data.ip?.status,
              emailMessage: response.data.email?.status,
            }))
            setTimeout(() => {
              setState(prevState => ({
                ...prevState,
                domainMessage: '',
                ipMessage: '',
                emailMessage: '',
              }))
            }, 2000)
          }
        }
        )
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const onDomainValueChange = (e) => {
    setState(prevState => ({ ...prevState, domain: e.target.value }))
  }

  const onIpValueChange = (e) => {
    setState(prevState => ({ ...prevState, ip: e.target.value }))
  }

  const onEmailValueChange = (e) => {
    setState(prevState => ({ ...prevState, email: e.target.value }))
  }

  const handleShowModal = () => {
    setState(prevState => ({ ...prevState, showModal: true }));
  };

  const handleCloseModal = () => setState(initialState);

  const handleTabSelect = (tab) => {
    setState(prevState => ({ ...prevState, activeTab: tab }));
  };

  return (
    <>
      <Button
        variant="outline-primary"
        onClick={handleShowModal}
      >
        SignUp Abuse
      </Button>
      <Modal
        show={state.showModal}
        onHide={handleCloseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title className='fs-6 fw-bold'>SignUp Abuse Limitter</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body-bg-color'>
          <Tabs
            activeKey={state.activeTab}
            onSelect={handleTabSelect}
            id="uncontrolled-tab-example"
            className="mb-3 d-flex justify-content-around"
          >
            <Tab
              eventKey="Domain/IP"
              title="Domain/IP"
            >
              <Form>
                <div className='row'>
                  <Form.Group
                    className='d-flex'
                  >
                    <div className='col-md-3'>
                      <Form.Label className='w-100 py-2'>Domain:</Form.Label>
                    </div>
                    <div className='col-md-9'>
                      <Form.Control
                        type="text"
                        placeholder="Domain"
                        onChange={onDomainValueChange}
                      />
                      {state.errors ? <p className='text-danger m-0'>{state.errors.domain}</p> : null}
                      <p className='m-0'>{state.domainMessage ?? null}</p>
                    </div>
                  </Form.Group>
                </div>
                <div className='row'>
                  <Form.Group
                    className='d-flex'
                  >
                    <div className='col-md-3'>
                      <Form.Label className='w-100 py-2'>IP:</Form.Label>
                    </div>
                    <div className='col-md-9'>
                      <Form.Control
                        type="text"
                        placeholder="IP"
                        maxLength={50}
                        onChange={onIpValueChange}
                      />
                      {state.errors ? <p className='text-danger m-0'>{state.errors.ip}</p> : null}
                      <p className='m-0'>{state.ipMessage ?? null}</p>
                    </div>
                  </Form.Group>
                </div>
                <Modal.Footer>
                  <Button
                    variant="outline-primary"
                    onClick={handleSaveChanges('add')}
                  >
                    Block
                  </Button>
                  <Button
                    variant="outline-primary"
                    onClick={handleSaveChanges('check')}
                  >
                    Check
                  </Button>
                  <Button
                    variant="outline-primary"
                    onClick={handleSaveChanges('remove')}
                  >
                    Unblock
                  </Button>
                </Modal.Footer>
              </Form>
            </Tab>
            <Tab
              eventKey="Email"
              title="Email"
            >
              <Form>
                <div className='row'>
                  <Form.Group
                    className='d-flex'
                  >
                    <div className='col-md-3'>
                      <Form.Label className='w-100 py-2'>Email:</Form.Label>
                    </div>
                    <div className='col-md-9'>
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        onChange={onEmailValueChange}
                      />
                      {state.errors ? <p className='text-danger m-0'>{state.errors.email}</p> : null}
                      <p className='m-0'>{state.emailMessage ?? null} </p>
                    </div>
                  </Form.Group>
                </div>
                <Modal.Footer>
                  <Button
                    variant="outline-primary"
                    onClick={handleSaveChanges('add')}
                  >
                    Whitelist
                  </Button>
                  <Button
                    variant="outline-primary"
                    onClick={handleSaveChanges('check')}
                  >
                    Check
                  </Button>
                  <Button
                    variant="outline-primary"
                    onClick={handleSaveChanges('remove')}
                  >
                    Delist
                  </Button>
                </Modal.Footer>
              </Form>
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SignUpAbuseLimiter;
