import React, { useState, useEffect } from 'react';

import * as Constants from '../../../../helpers/constants';
import { LIST_STATUSES } from '../../helpers/constants';

import { CircularProgressbar } from 'react-circular-progressbar';

import 'react-circular-progressbar/dist/styles.css';

const CircularProgress = ({
  listDetails,
  service
}) => {
  const [details, setDetails] = useState(listDetails);

  useEffect(() => {
    setDetails(listDetails);
  }, [listDetails]);

  const getProgressAndAction = () => {
    if (service.NAME === 'prospect') {
      return {
        progress: details.enrich_progress || { progress: 0 },
        actionInProgress: details.enrich_action_inprogress || { action: '' },
      };
    }
    return {
      progress: details.progress || { progress: 0 },
      actionInProgress: details.action_inprogress || { action: '' },
    };
  };

  const showVerifyOrCancel = () => {
    const { actionInProgress } = getProgressAndAction();

    if (details.status === LIST_STATUSES.CANCELLED) {
      return (
        <div className="progress-info-div">
          <p className="w-auto mt-2 mx-auto">
            <span>Cancelling...</span>
          </p>
        </div>
      );
    } else {
      const actionStatus = Constants.LIST_ACTIONS[actionInProgress.action]?.progress || 'Processing...';
      return (
        <p className="w-auto mt-2 mx-auto">
          <span>{actionStatus}</span>
        </p>
      );
    }
  };

  const { progress } = getProgressAndAction();
  const progressVal = progress.progress || 0;
  const text = progressVal !== null ? `${progressVal.toFixed(2)}%` : '';

  return (
    <div className="progress-info-div">
      <div className="file-upload-progress">
        <CircularProgressbar
          value={progressVal !== null ? progressVal.toFixed(2) : 0}
          text={text}
          strokeWidth={5}
          initialAnimation={true}
          styles={{
            path: {
              stroke: '#63b306',
            },
            text: {
              fill: '#63b306',
            },
          }}
        />
        {showVerifyOrCancel()}
      </div>
    </div>
  );
};

export default CircularProgress;
