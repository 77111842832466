/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState, useContext } from 'react'
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { Badge } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Toggle from 'react-toggle';
import { Container, Row, Col, Card, Button, Tab, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Breadcrumbs from '../../widgets/breadcrumb';
import * as Constants from '../../helpers/constants';
import ToggleRadarModel from './toggle_radar';
import TabsInfo from './tabs';
import LoadingWrapper from '../widgets/loading';
import * as Helpers from '../../helpers/helper'
import showToastNotification from '../widgets/toastify';
import * as IndividualUserConstants from './helpers/constant'
import * as UserInfoFormatters from './helpers/user_info_formatters'
import ActionButtons from './action_buttons';
import QuickLinks from './quick_links'
import ExtraUserInfo from './extra_user_info';
import { ApiHelper } from '../../helpers/apihelper';
import AppConfigContext from '../app_layout/app_config_context'
import AddBonusModel from './add_bonus';
import ResetBonusModel from './reset_bonus';


import './../../static/css/loader.css'
import './../../static/css/profile.css'
import 'react-toggle/style.css'


const initialState = {
  userData: null,
  isRadarModalOpen: false,
  isChecked: true,
  additionalUserData: {},
  isLoading: false,
  imageUrl: '',
  userSubscriptions: []
}

const ClearoutIndividualUserInfo = ({ userId }) => {
  const [state, setState] = useState(initialState)
  const id = useSelector((state) => state.user.id);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const user_id = queryParams.get('user_id') || userId;

  const appConfig = useContext(AppConfigContext)
  const client = appConfig.client

  const getClearoutUserInfo = useCallback(() => {
    let requestBody = {
      co_user_id: user_id,
      user_id: user_id,
      client_secret: Constants.CLIENT_SECRET
    }
    let body = _.pick(requestBody, ApiHelper(client, 'USER_INFO').API_BODY)

    ApiHelper(client, 'USER_INFO').API(client, body)
      .then((response) => {
        if (response && response.status === Constants.CO_API.SUCCESS) {
          setState(prevState => ({ ...prevState, userData: response.data, imageUrl: response.data.profile_picture }))
          {
            client === 'clearout' ? (
              setState(prevState => ({ ...prevState, isChecked: response.data.radar.active }))
            ) : null
          }
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [user_id, client])

  const getClearoutUserAdditionalInfo = useCallback(() => {
    let requestBody = {
      co_user_id: user_id,
      cop_user_id: user_id,
      client_secret: Constants.CLIENT_SECRET
    }
    let body = _.pick(requestBody, ApiHelper(client, 'ADDITIONAL_INFO').API_BODY)

    ApiHelper(client, 'ADDITIONAL_INFO').API(client, body)
      .then((response) => {
        if (response && response.status === Constants.CO_API.SUCCESS) {
          // console.log(response.data)
          setState(prevState => ({ ...prevState, additionalUserData: response.data }))
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }, [user_id, client])

  const getUserInfo = useCallback(() => {
    getClearoutUserInfo()
    getClearoutUserAdditionalInfo()
  }, [getClearoutUserInfo, getClearoutUserAdditionalInfo])

  useEffect(() => {
    getUserInfo()
  }, [getUserInfo])


  const getUsersSubscriptions = useCallback(() => {
    let requestBody = {
      co_user_id: user_id,
    }
    let body = _.pick(requestBody, ApiHelper(client, 'USER_SUBSCRIPTIONS').API_BODY)
    ApiHelper(client, 'USER_SUBSCRIPTIONS').API(client, body)
      .then((response) => {
        if (response.status === Constants.CO_API.SUCCESS) {
          setState(prevState => ({
            ...prevState,
            userSubscriptions: response.data[0]
          }))
        } else {
          let message = response.message || 'Something went wrong'
          showToastNotification({
            message,
            isSuccess: false,
          });
        }
      })
      .catch((err) => {
        let message = err.message || 'Something went wrong'
        showToastNotification({
          message,
          isSuccess: false,
        });
      })
  }, [user_id, client])

  // mount
  useEffect(() => {
    getUsersSubscriptions()
  }, [getUsersSubscriptions])

  const handletoggleModal = () => {
    setState(prevState => ({ ...prevState, isRadarModalOpen: false }))
  };

  const handleToggleChange = () => {
    let requestBody = {
      co_user_id: user_id,
      user_id: id,
      reason: state.reason,
      radarStatus: true
    };

    let body = _.pick(requestBody, ApiHelper(client, 'RADAR').API_BODY)
    // Toggle the isChecked state
    setState(prevState => ({ ...prevState, isChecked: !state.isChecked }))

    // If the toggle is deactivated, open the modal
    if (state.isChecked) {
      setState(prevState => ({ ...prevState, isRadarModalOpen: true }));
    } else {
      handleToggleRadarApiCall(body);
    }
  };

  const handleToggleRadarApiCall = (body) => {
    ApiHelper(client, 'RADAR').API(client, body)
      .then((response) => {
        if (response.status === Constants.CO_API.FAILED) {
          showToastNotification({
            message: 'failed',
            isSuccess: false,
          });
        } else (response.status === Constants.CO_API.SUCCESS); {
          showToastNotification({
            message: 'Radar settings updated successfully',
            isSuccess: true,
          });
          handletoggleModal();
        }
      })
      .catch((error) => {
        console.log(error)
      });
  };

  //data formatting 
  const getIconColor = (data) => {
    if (data.account_status === 'removed') {
      return '#ff6767'
    } else if (!data.activated_on || data.activated_on === 'Yet to Activate') {
      return '#a3a3a3'
    } else if (data.status === 'active') {
      return '#6229e3'
    } else {
      return '#e48725'
    }
  }

  const getLeftTable = () => {
    const leftTable = IndividualUserConstants.BASIC_USER_INFO[client].OVERVIEW_SECTION

    return (
      <>
        {leftTable.map((opts, idx) => {
          const accessorValue = _.get(state.userData, opts.accessorKey);
          return (
            <div
              key={idx}
              className='py-1'
            >
              <p className='mb-1 text-muted fw-bold'>{opts.label}</p>
              {UserInfoFormatters[opts.formatter]({
                client,
                value: accessorValue,
                userData: state.userData,
                additionalData: state.additionalUserData,
                user_id,
                getUserInfo,
                subscriptionData: state.userSubscriptions,
                isChecked: state.isChecked,
                handleToggleChange: handleToggleChange
              })}
            </div>
          )
        })}
      </>
    )
  }

  const getRightTable = () => {
    const rightTable = IndividualUserConstants.BASIC_USER_INFO[client].RIGHT_TABLE

    return (
      <>
        {rightTable.map((opts, idx) => {
          const accessorValue = _.get(state.userData, opts.accessorKey);
          return (
            <div
              key={idx}
              className='py-3'
            >
              <p className='mb-1 text-muted fw-bold'>{opts.label}</p>
              {UserInfoFormatters[opts.formatter]({
                client,
                value: accessorValue,
                userData: state.userData,
                additionalData: state.additionalUserData,
                user_id,
                getUserInfo,
                isChecked: state.isChecked,
                handleToggleChange: handleToggleChange
              })}
            </div>
          )
        })}
      </>
    )
  }


  const getOrgAccountTab = () => {
    const rightTable = IndividualUserConstants.BASIC_USER_INFO[client].ORG_TAB

    return (
      <>
        {rightTable.map((opts, idx) => {
          const accessorValue = _.get(state.userData, opts.accessorKey);
          return (
            <div
              key={idx}
              className='py-3'
            >
              <p className='mb-1 text-muted fw-bold'>{opts.label}</p>
              {UserInfoFormatters[opts.formatter]({
                client,
                value: accessorValue,
                userData: state.userData,
                additionalData: state.additionalUserData,
                user_id,
                getUserInfo,
                isChecked: state.isChecked,
                handleToggleChange: handleToggleChange
              })}
            </div>
          )
        })}
      </>
    )
  }

  const getBadges = () => {
    let tagsToShow = []

    // Handle both string and object types in the tags array
    state.userData.tags.forEach(tag => {
      if (typeof tag === 'string') {
        tagsToShow.push(tag)
      } else if (typeof tag === 'object' && tag.type) {
        tagsToShow.push(tag.type)
      }
    })

    let userRole = state.userData.user_role
    if (userRole === 'analyst')
      userRole = 'executive'

    tagsToShow.push(userRole)

    let backgrounds = ['primary', 'success', 'danger', 'warning', 'info']

    return (
      <div className='d-flex gap-1'>
        {
          tagsToShow.map(tag => {
            return (
              <Badge
                key={tag}
                bg={backgrounds[_.random(0, backgrounds.length - 1)]}
              >
                {Helpers.capitalizeFirstLetter(tag)}
              </Badge>
            )
          })
        }
      </div>
    )
  }


  if (!state.userData) {
    return <p>No Data</p>
  }

  const radarFormatter = () => { ///////////todo temporary////////
    return (
      <>
        {state.userData && state.userData.user_role === 'owner' ? (
          <Toggle
            checked={state.isChecked}
            onChange={handleToggleChange}
            disabled={
              state.userData.status === 'active' &&
                state.userData.account_status === 'live' &&
                state.userData.activated_on
                ? false
                : true
            }
          />
        ) : (
          '-'
        )}
      </>
    )
  }

  const remainingCreditsFormatter = () => {
    return (
      <div className="d-flex gap-2 remaining-credits">
        {state.userData.user_type === 'individual' ? (
          <AddBonusModel
            client={client}
            getUserInfo={getUserInfo}
            name={state.userData.name}
            email={state.userData.email}
            userData={state.userData}
          />
        ) : null}
        {state.userData.user_type === 'individual' ? (
          <ResetBonusModel
            client={client}
            getUserInfo={getUserInfo}
            name={state.userData.name}
            userData={state.userData}
          />
        ) : null}
      </div>
    )
  }

  return (
    // <div className='container-fluid'>
    //   {state.isLoading ? (
    //     <div className='loader-container'>
    //       <LoadingWrapper
    //         type='bars'
    //         color='#007bff'
    //         height={50}
    //         width={50}
    //       />
    //     </div>
    //   ) : null}
    //   <div
    //     className='container-fluid'
    //   >
    //     <Breadcrumbs options={Constants.BREAD_CRUMB_OPTIONS[client]} />
    //     <div className='info-section-container'>
    //       <div className='basic-info-section mb-3 p-3'>
    //         <div className='d-flex justify-content-center pt-2'>
    //           <h3
    //             className='text-center'
    //             key={state.userData.status}
    //           >
    //             <i
    //               className={state.userData.user_type === 'organization' ? 'fa fa-users' : 'fa fa-user'}
    //               style={{ color: getIconColor(state.userData) }}
    //             />
    //             <a
    //               style={{ color: getIconColor(state.userData) }}
    //             >
    //               &nbsp;&nbsp;{state.userData.name ? Helpers.capitalizeFirstLetter(state.userData.name) : state.userData.email}&nbsp;
    //             </a>
    //           </h3>
    //         </div>
    //         <div className='d-flex justify-content-center'>
    //           {getBadges()}
    //         </div>
    //         <div className='d-flex justify-content-end gap-3 py-2'>
    //           <QuickLinks
    //             client={client}
    //             userId={userId}
    //             userData={state.userData}
    //           />
    //         </div>
    //         <div className='d-flex user-info pt-2'>
    //           <div className='col-md-5 p-0'>
    //             {getLeftTable()}
    //           </div>
    //           <div className='col-md-4 p-0'>
    //             {getRightTable()}
    //           </div>
    //           <div className='col-md-3 p-0'>
    //             <ActionButtons
    //               client={client}
    //               userData={state.userData}
    //               getUserInfo={getUserInfo}
    //             />
    //           </div>
    //         </div>
    //       </div>
    //       <div className='px-3 extra-info-section'>
    //         <ExtraUserInfo
    //           userData={state.userData}
    //           client={client}
    //           isChecked={state.isChecked}
    //           handleToggleChange={handleToggleChange}
    //           additionalData={state.additionalUserData}
    //           getUserInfo={getUserInfo}
    //         />
    //       </div>
    //     </div>
    //   </div>
    //   {/* tab section */}
    //   <div className='p-3'>
    //     <TabsInfo
    //       userData={state.userData}
    //       getUserInfo={getUserInfo}
    //       client={client}
    //       userId={user_id}
    //     />
    //   </div>
    //   <ToggleRadarModel
    //     show={state.isRadarModalOpen}
    //     onHide={handletoggleModal}
    //     co_user_id={user_id}
    //     name={state.userData.name}
    //     handleToggleRadar={handleToggleRadarApiCall}
    //     getUserInfo={getUserInfo}
    //     client={client}
    //   />
    // </div>
    <>
      <Breadcrumbs options={Constants.BREAD_CRUMB_OPTIONS[client]} />
      <Container
        fluid
        className='p-4'
        style={{ height: '100vh' }}
      >
        <Row className='h-100'>
          {/* Left Panel */}
          <Col
            md={3}
            className='d-flex flex-column'
          >
            <Card className='p-3 h-100'>
              <div className='d-flex align-items-center text-center mb-3'>
                <img
                  src={state.imageUrl || 'https://wallpapers.com/images/hd/placeholder-profile-icon-20tehfawxt5eihco.jpg'}
                  alt='Avatar'
                  className='rounded-circle me-3'
                  style={{ width: '80px', height: '80px' }}
                />
                <div>
                  <h5>{state.userData.name}</h5>
                  <p className='text-muted'>{state.additionalUserData && state.additionalUserData.job_title
                    ? Helpers.capitalizeFirstLetter(state.additionalUserData.job_title)
                    : '-'}
                  </p>
                </div>
              </div>
              <div className='d-flex gap-4'>
                <FontAwesomeIcon icon='fa-solid fa-envelope' />
                <FontAwesomeIcon icon='fa-solid fa-phone' />
                <FontAwesomeIcon icon='fa-brands fa-linkedin' />
              </div>
              <div>
                <Tab.Container defaultActiveKey='account'>
                  <Nav
                    variant='tabs'
                    className='d-flex justify-content-center py-3'
                  >
                    <Nav.Item>
                      <Nav.Link eventKey='account'>Account</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey='organization'>Organization</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content className='p-3'>
                    <Tab.Pane eventKey='account'>
                      <div>
                        <p className='text-muted m-0'>
                          {getRightTable()}
                        </p>
                        <div className='d-flex justify-content-between'>
                          <p className='fw-bold text-muted'>Tags</p>
                          <Button
                            variant='outline-primary'
                            size="sm"
                            className='sm-btns'
                          >
                            <FontAwesomeIcon icon='fa-solid fa-circle-plus' />
                          </Button>
                        </div>
                      </div>
                      <div className='d-flex gap-2'>
                        {getBadges()}
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey='organization'>
                      {getOrgAccountTab()}
                      <p className='fw-bold text-muted m-0'>Org Credits</p>
                      <p className='m-0'>-</p>
                      <p className='fw-bold text-muted m-0'>Team Members</p>
                      <p className='m-0'>-</p>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Card>
          </Col>
          {/* Right Panel */}
          <Col
            md={9}
            className='d-flex flex-column'
          >
            <Card className='p-3 w-100 bg-gray'>
              <h5 className='text-muted pb-2 ps-2'>Overview</h5>
              <div className="container">
                <div className="row">
                  {/* Left Section */}
                  <div className="col-lg-9">
                    <div className="row gy-3">
                      {getLeftTable().props.children.map((item, idx) => (
                        <div
                          className="col-md-4"
                          key={idx}
                        >
                          <div className="p-1 px-3 border rounded bg-white">
                            {item}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {/* Right Section */}
                  <div className="col-lg-3 bg-gray">
                    <div className="p-3 border">
                      <p className="fw-bold text-center">Quick Actions</p>
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <p className='m-0'>Radar</p>
                        {radarFormatter()}
                      </div>
                      <div className="d-flex justify-content-between align-items-center py-3">
                        <p className='m-0'>Add Credits</p>
                        {remainingCreditsFormatter()}
                      </div>
                      <Button
                        variant='primary'
                        className="w-100 mb-2"
                      >
                        Generate Password Link
                      </Button>
                      <ActionButtons
                        client={client}
                        userData={state.userData}
                        getUserInfo={getUserInfo}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Card>
            {/* Tabs */}
            <Card className='mt-3 flex-grow-1 w-100'>
              <TabsInfo
                userData={state.userData}
                getUserInfo={getUserInfo}
                client={client}
                userId={user_id}
              />
            </Card>
          </Col>
        </Row>
        <ToggleRadarModel
          show={state.isRadarModalOpen}
          onHide={handletoggleModal}
          co_user_id={user_id}
          name={state.userData.name}
          handleToggleRadar={handleToggleRadarApiCall}
          getUserInfo={getUserInfo}
          client={client}
        />
      </Container>
    </>
  )
}
export default ClearoutIndividualUserInfo
