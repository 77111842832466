import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';

import * as Constants from '../../helpers/constants'
import showToastNotification from '../widgets/toastify'
import { ApiHelper } from '../../helpers/apihelper';

const initialState = {
  selectedApi: null,
  rateLimit: '',
  reason: '',
  isApiCallInProgress: false,
  showModal: false,
  errors: {},
}

function UpdateRateLimitModal({ data, getUserInfo, client }) {
  const [state, setState] = useState(initialState)
  const id = useSelector(state => state.user.id);
  const userName = useSelector(state => state.user.name);

  const options = Constants.API_RATE_LIMIT_OPTIONS[client]

  const getApiRateLimit = () => {
    let api_rate_limits = {}
    // Check if the client exists in OPTIONS
    if (Constants.API_RATE_LIMIT_OPTIONS[client]) {
      const selectedApiOption = Constants.API_RATE_LIMIT_OPTIONS[client].find(option => option.label === state.selectedApi.label)
      if (selectedApiOption) {
        if (selectedApiOption.value === 'all') {
          // Map all available API options for the client
          Constants.API_RATE_LIMIT_OPTIONS[client].forEach(option => {
            if (option.value !== 'all') {
              api_rate_limits[option.value] = {
                maxRequests: state.rateLimit,
                windowIntervalInSecs: '60'
              }
            }
          })
        } else {
          // Single API case
          api_rate_limits[selectedApiOption.value] = {
            maxRequests: state.rateLimit,
            windowIntervalInSecs: '60'
          }
        }
      }
    }
    return api_rate_limits
  }


  const validateForm = () => {
    let err = {}
    if (state.rateLimit.toString().length > 10) {
      err['limits'] = 'Please enter limits less than 10 chars'
    }
    // if (state.rateLimit <= 0) {
    //   err['limits'] = 'limit must be a positive number';
    // }
    if (Object.keys(err).length) {
      setState((prevState) => ({ ...prevState, errors: err }))
      setTimeout(() => {
        setState((prevState) => ({ ...prevState, errors: {} }))
      }, 5000);
      return false
    }
    return true
  }

  const handleSaveChanges = () => {
    if (validateForm()) {
      let requestBody = {
        api_rate_limits: getApiRateLimit(),
        co_user_id: data.user_id,
        cop_user_id: data.user_id,
        reason: state.reason,
        user_id: id,
        client_secret: Constants.CLIENT_SECRET,
        crm_user: {
          name: userName,
          user_id: id,
        },
      };

      let body = _.pick(requestBody, ApiHelper(client, 'UPDATE_API_RATE_LIMIT').API_BODY)

      setState(prevState => ({ ...prevState, isApiCallInProgress: true }));
      ApiHelper(client, 'UPDATE_API_RATE_LIMIT').API(client, body)
        .then((response) => {
          if (response.status === Constants.CO_API.FAILED) {
            showToastNotification({
              message: 'Failed',
              isSuccess: false,
            });
          } else if (response.status === Constants.CO_API.SUCCESS) {
            getUserInfo()
            handleCloseModal()
            setState((prevState) => ({
              ...prevState,
              showModal: false,
            }));
            showToastNotification({
              message: 'API RateLimit updated successfully',
              isSuccess: true,
            });
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          setState(prevState => ({ ...prevState, isApiCallInProgress: false }));
        })
    }
  };

  const handleShowModal = () => {
    setState(prevState => ({ ...prevState, showModal: true }));
  };

  const handleCloseModal = () => setState(initialState)

  return (
    <>
      <Button
        size="sm"
        variant='outline-primary'
        onClick={handleShowModal}
      >
        <FontAwesomeIcon icon="fa fa-pencil" />
      </Button>
      <Modal
        show={state.showModal}
        onHide={handleCloseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title className='fs-6 fw-bold'>
            Update API Rate Limit to {data?.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body-bg-color'>
          <Form>
            <div className='row'>
              <Form.Group
                className='d-flex'
              >
                <div className='col-md-3'>
                  <Form.Label className='w-100 py-2'>API:</Form.Label>
                </div>
                <div className='col-md-9'>
                  <Select
                    options={options}
                    isClearable={true}
                    searchable={true}
                    placeholder="Select Service"
                    value={state.selectedApi}
                    onChange={(selectedOption) => setState(prevState => ({ ...prevState, selectedApi: selectedOption }))}
                  />
                </div>
              </Form.Group>
            </div>
            <div className='row'>
              <Form.Group
                className='d-flex'
              >
                <div className='col-md-3'>
                  <Form.Label className='w-100 py-2'>New Rate Limit:</Form.Label>
                </div>
                <div className='col-md-9'>
                  <Form.Control
                    type="number"
                    placeholder="Enter API Rate Limit"
                    value={state.rateLimit}
                    onChange={(e) => setState(prevState => ({ ...prevState, rateLimit: e.target.value }))}
                  />
                  {state.errors ? <p className='text-danger'>{state.errors.limits}</p> : null}
                </div>
              </Form.Group>
            </div>
            <div className='row'>
              <Form.Group
                className='d-flex'
              >
                <div className='col-md-3'>
                  <Form.Label className='w-100 py-2'>Reason:</Form.Label>
                </div>
                <div className='col-md-9'>
                  <Form.Control
                    type="text"
                    placeholder="Enter Reason"
                    value={state.reason}
                    maxLength={50}
                    onChange={(e) => setState(prevState => ({ ...prevState, reason: e.target.value }))}
                  />
                </div>
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={handleSaveChanges}
            disabled={!state.selectedApi || !state.rateLimit || !state.reason || state.isApiCallInProgress}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UpdateRateLimitModal;
