import React from 'react'

import UserSubscriptions from './components/user_subscriptions'
import CopUserSubscription from './components/cop_user_subscriptions'

import { CLIENTS } from '../../../../helpers/constants'

const SubscriptionHistory = ({ userData, client }) => {

  return (
    <div className='subscription-history-tab'>
      <div className='subscription-history-tab__body'>
        {client === CLIENTS.CLEAROUTPHONE.NAME ? (
          <CopUserSubscription
            userData={userData}
            client={client}
          />
        ) :
          <UserSubscriptions
            client={client}
            userData={userData}
          />
        }
      </div>
    </div>
  )
}

export default SubscriptionHistory
