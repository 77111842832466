import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Table from 'react-bootstrap/Table';


import * as Constants from '../../../../helpers/constants';
import { DELIVERABILITY_TABLE_DISPLAY_ORDER } from '../../helpers/constants';
import * as Helper from '../../../../helpers/helper';

const DeliverabilityScoreTable = ({
  service,
  ...props
}) => {
  const [state, setState] = useState({
    metrics: props.metrics,
    resultsArray: props.resultsArray,
    lastVerifiedOn: props.lastVerifiedOn,
    individualList: props.individualList,
  });

  useEffect(() => {
    setState({
      metrics: props.metrics,
      resultsArray: props.resultsArray,
      lastVerifiedOn: props.lastVerifiedOn,
      individualList: props.individualList,
    });
  }, [props.metrics, props.resultsArray, props.lastVerifiedOn, props.individualList]);

  const getDeliverabilityScore = (deliverability_score) => {
    const score = state.lastVerifiedOn ? Helper.getDeliveryStatusFromDeliverableScore(deliverability_score) : '-';
    return state.lastVerifiedOn ? (
      <td className='deliverability-value'>
        {deliverability_score}%
        <span className={classNames('delivery_status', score.value)}>
          ({score.name})
        </span>
      </td>
    ) : (
      <td className='deliverability-value'>-</td>
    );
  };

  let confidence_level_text = '';
  const deliverabilityTableDisplayOrder = DELIVERABILITY_TABLE_DISPLAY_ORDER[service.NAME]

  return (
    <Table
      className='deliverability-results-info-table'
      borderless
      size='sm'
    >
      <tbody>
        {deliverabilityTableDisplayOrder.map((result, index) => {
          if (result === 'confidence_level') {
            confidence_level_text = state.metrics[result] || '-';
            confidence_level_text = confidence_level_text.replace(/\s/g, '-');
          }
          return (
            <tr key={index}>
              <td>{Constants.RESULTS[result].display_name}</td>
              {result === 'deliverability_score' ? (
                getDeliverabilityScore(state.metrics[result])
              ) : (
                <td className={classNames('deliverability-value', confidence_level_text ? `${confidence_level_text}` : '')}>
                  {Helper.getResultAsReqFormat(result, state.metrics[result])}
                </td>
              )}
            </tr>
          );
        })}
        {state.individualList.verification_result && state.individualList.verification_result.safe_to_send ? (
          <tr>
            <td>
              <div className='result-name-data'>
                Guaranteed Deliverables
              </div>
            </td>
            <td>
              <div className='deliverability-value delivery_status no_risk'>
                {state.individualList.verification_result.safe_to_send['yes'].value}
              </div>
            </td>
          </tr>
        ) : null}
      </tbody>
    </Table>
  );
};

export default DeliverabilityScoreTable;
