import { CLIENTS } from '../../../helpers/constants'

export const FILTERS_TO_INCLUDE = {
  [CLIENTS.CLEAROUT.NAME]: {
    USER_LIST_PAGE: ['main_filters', 'daterange_filter', 'reset_filter', 'org_filter', 'search_filter'],
    ANALYTICS: ['main_filters', 'reset_filter'],
    PRODUCT_LIST_PAGE: ['main_filters', 'reset_filter', 'search_filter'],
    PLANS_TAB: ['main_filters', 'reset_filter'],
    ABUSE_LOGS: ['main_filters', 'daterange_filter', 'reset_filter', 'search_filter'],
    MEMBERS_LIST: ['main_filters', 'daterange_filter', 'reset_filter', 'search_filter']
  },
  [CLIENTS.CLEAROUTPHONE.NAME]: {
    USER_LIST_PAGE: ['main_filters', 'daterange_filter', 'reset_filter', 'search_filter'],
    ANALYTICS: ['main_filters', 'reset_filter'],
    ABUSE_LOGS: ['main_filters', 'daterange_filter', 'reset_filter', 'search_filter']
  },
  [CLIENTS.SPOKESLY_CLEAROUT.NAME]: {
    USER_LIST_PAGE: ['main_filters', 'daterange_filter', 'reset_filter', 'org_filter', 'search_filter'],
    ANALYTICS: ['main_filters', 'reset_filter'],
    PRODUCT_LIST_PAGE: ['main_filters', 'reset_filter', 'search_filter'],
    PLANS_TAB: ['main_filters', 'reset_filter'],
    ABUSE_LOGS: ['main_filters', 'daterange_filter', 'reset_filter', 'search_filter'],
    MEMBERS_LIST: ['main_filters', 'daterange_filter', 'reset_filter', 'search_filter']
  },
  [CLIENTS.EU_CLEAROUT.NAME]: {
    USER_LIST_PAGE: ['main_filters', 'daterange_filter', 'reset_filter', 'org_filter', 'search_filter'],
    ANALYTICS: ['main_filters', 'reset_filter'],
    PRODUCT_LIST_PAGE: ['main_filters', 'reset_filter', 'search_filter'],
    PLANS_TAB: ['main_filters', 'reset_filter'],
    ABUSE_LOGS: ['main_filters', 'daterange_filter', 'reset_filter', 'search_filter'],
    MEMBERS_LIST: ['main_filters', 'daterange_filter', 'reset_filter', 'search_filter']
  },
  [CLIENTS.EP_CLEAROUT.NAME]: {
    USER_LIST_PAGE: ['main_filters', 'daterange_filter', 'reset_filter', 'org_filter', 'search_filter'],
    ANALYTICS: ['main_filters', 'reset_filter'],
    PRODUCT_LIST_PAGE: ['main_filters', 'reset_filter', 'search_filter'],
    PLANS_TAB: ['main_filters', 'reset_filter'],
    ABUSE_LOGS: ['main_filters', 'daterange_filter', 'reset_filter', 'search_filter'],
    MEMBERS_LIST: ['main_filters', 'daterange_filter', 'reset_filter', 'search_filter']
  },
  [CLIENTS.PEPIPOST_CLEAROUT.NAME]: {
    USER_LIST_PAGE: ['main_filters', 'daterange_filter', 'reset_filter', 'org_filter', 'search_filter'],
    ANALYTICS: ['main_filters', 'reset_filter'],
    PRODUCT_LIST_PAGE: ['main_filters', 'reset_filter', 'search_filter'],
    PLANS_TAB: ['main_filters', 'reset_filter'],
    ABUSE_LOGS: ['main_filters', 'daterange_filter', 'reset_filter', 'search_filter'],
    MEMBERS_LIST: ['main_filters', 'daterange_filter', 'reset_filter', 'search_filter']
  },
  [CLIENTS.CAMS_CLEAROUT.NAME]: {
    USER_LIST_PAGE: ['main_filters', 'daterange_filter', 'reset_filter', 'org_filter', 'search_filter'],
    ANALYTICS: ['main_filters', 'reset_filter'],
    PRODUCT_LIST_PAGE: ['main_filters', 'reset_filter', 'search_filter'],
    PLANS_TAB: ['main_filters', 'reset_filter'],
    ABUSE_LOGS: ['main_filters', 'daterange_filter', 'reset_filter', 'search_filter'],
    MEMBERS_LIST: ['main_filters', 'daterange_filter', 'reset_filter', 'search_filter']
  }
}

export const USER_LIST_PAGE = {
  [CLIENTS.CLEAROUT.NAME]: {
    filters: [
      {
        name: 'Billed',
        value: 'subs_plan_type',
        menus: [
          { label: 'Monthly', value: 'monthly' },
          { label: 'Annually', value: 'yearly' },
        ],
      },
      {
        name: 'Subs Status',
        value: 'subs_status',
        menus: [
          { label: 'Active', value: 'active' },
          { label: 'Cancelled', value: 'cancelled' },
        ],
      },
      {
        name: 'Payment Status',
        value: 'subs',
        menus: [
          { label: 'All', value: 'none' },
          { label: 'Subscribed', value: 'subscriber' },
          { label: 'Paid', value: 'paid' },
        ],
      },
      {
        name: 'User Status',
        value: 'status',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Active', value: 'active' },
          { label: 'Inactive', value: 'inactive' },
        ],
      }
    ],
    onResetFilters: {
      date_range: {
        filter_label: 'date_range',
        value: {
          key: 'created_on',
          preset: 'ps_last_7_days_including_today'
        }
      },
      status: {
        filter_label: 'User Status',
        label: 'All',
        value: 'all'
      }
    }
  },
  [CLIENTS.CLEAROUTPHONE.NAME]: {
    filters: [
      {
        name: 'Billed',
        value: 'subs_plan_type',
        menus: [
          { label: 'Monthly', value: 'monthly' },
          { label: 'Annually', value: 'yearly' },
        ],
      },
      {
        name: 'Subs Status',
        value: 'subs_status',
        menus: [
          { label: 'Active', value: 'active' },
          { label: 'Cancelled', value: 'cancelled' },
        ],
      },
      {
        name: 'Payment Status',
        value: 'subs',
        menus: [
          { label: 'All', value: 'none' },
          { label: 'Subscribed', value: 'subscriber' },
          { label: 'Paid', value: 'paid' },
        ],
      },
      {
        name: 'User Status',
        value: 'status',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Active', value: 'active' },
          { label: 'Inactive', value: 'inactive' },
        ],
      }
    ],
    onResetFilters: {
      date_range: {
        filter_label: 'date_range',
        value: {
          key: 'created_on',
          preset: 'ps_last_7_days_including_today'
        }
      },
      status: {
        filter_label: 'User Status',
        label: 'All',
        value: 'all'
      }
    }
  },
  [CLIENTS.SPOKESLY_CLEAROUT.NAME]: {
    filters: [
      {
        name: 'Billed',
        value: 'subs_plan_type',
        menus: [
          { label: 'Monthly', value: 'monthly' },
          { label: 'Annually', value: 'yearly' },
        ],
      },
      {
        name: 'Subs Status',
        value: 'subs_status',
        menus: [
          { label: 'Active', value: 'active' },
          { label: 'Cancelled', value: 'cancelled' },
        ],
      },
      {
        name: 'Payment Status',
        value: 'subs',
        menus: [
          { label: 'All', value: 'none' },
          { label: 'Subscribed', value: 'subscriber' },
          { label: 'Paid', value: 'paid' },
        ],
      },
      {
        name: 'User Status',
        value: 'status',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Active', value: 'active' },
          { label: 'Inactive', value: 'inactive' },
        ],
      }
    ],
    onResetFilters: {
      date_range: {
        filter_label: 'date_range',
        value: {
          key: 'created_on',
          preset: 'ps_last_7_days_including_today'
        }
      },
      status: {
        filter_label: 'User Status',
        label: 'All',
        value: 'all'
      }
    }
  },
  [CLIENTS.EU_CLEAROUT.NAME]: {
    filters: [
      {
        name: 'Billed',
        value: 'subs_plan_type',
        menus: [
          { label: 'Monthly', value: 'monthly' },
          { label: 'Annually', value: 'yearly' },
        ],
      },
      {
        name: 'Subs Status',
        value: 'subs_status',
        menus: [
          { label: 'Active', value: 'active' },
          { label: 'Cancelled', value: 'cancelled' },
        ],
      },
      {
        name: 'Payment Status',
        value: 'subs',
        menus: [
          { label: 'All', value: 'none' },
          { label: 'Subscribed', value: 'subscriber' },
          { label: 'Paid', value: 'paid' },
        ],
      },
      {
        name: 'User Status',
        value: 'status',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Active', value: 'active' },
          { label: 'Inactive', value: 'inactive' },
        ],
      }
    ],
    onResetFilters: {
      date_range: {
        filter_label: 'date_range',
        value: {
          key: 'created_on',
          preset: 'ps_last_7_days_including_today'
        }
      },
      status: {
        filter_label: 'User Status',
        label: 'All',
        value: 'all'
      }
    }
  },
  [CLIENTS.EP_CLEAROUT.NAME]: {
    filters: [
      {
        name: 'Billed',
        value: 'subs_plan_type',
        menus: [
          { label: 'Monthly', value: 'monthly' },
          { label: 'Annually', value: 'yearly' },
        ],
      },
      {
        name: 'Subs Status',
        value: 'subs_status',
        menus: [
          { label: 'Active', value: 'active' },
          { label: 'Cancelled', value: 'cancelled' },
        ],
      },
      {
        name: 'Payment Status',
        value: 'subs',
        menus: [
          { label: 'All', value: 'none' },
          { label: 'Subscribed', value: 'subscriber' },
          { label: 'Paid', value: 'paid' },
        ],
      },
      {
        name: 'User Status',
        value: 'status',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Active', value: 'active' },
          { label: 'Inactive', value: 'inactive' },
        ],
      }
    ],
    onResetFilters: {
      date_range: {
        filter_label: 'date_range',
        value: {
          key: 'created_on',
          preset: 'ps_last_7_days_including_today'
        }
      },
      status: {
        filter_label: 'User Status',
        label: 'All',
        value: 'all'
      }
    }
  },
  [CLIENTS.PEPIPOST_CLEAROUT.NAME]: {
    filters: [
      {
        name: 'Billed',
        value: 'subs_plan_type',
        menus: [
          { label: 'Monthly', value: 'monthly' },
          { label: 'Annually', value: 'yearly' },
        ],
      },
      {
        name: 'Subs Status',
        value: 'subs_status',
        menus: [
          { label: 'Active', value: 'active' },
          { label: 'Cancelled', value: 'cancelled' },
        ],
      },
      {
        name: 'Payment Status',
        value: 'subs',
        menus: [
          { label: 'All', value: 'none' },
          { label: 'Subscribed', value: 'subscriber' },
          { label: 'Paid', value: 'paid' },
        ],
      },
      {
        name: 'User Status',
        value: 'status',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Active', value: 'active' },
          { label: 'Inactive', value: 'inactive' },
        ],
      }
    ],
    onResetFilters: {
      date_range: {
        filter_label: 'date_range',
        value: {
          key: 'created_on',
          preset: 'ps_last_7_days_including_today'
        }
      },
      status: {
        filter_label: 'User Status',
        label: 'All',
        value: 'all'
      }
    }
  },
  [CLIENTS.CAMS_CLEAROUT.NAME]: {
    filters: [
      {
        name: 'Billed',
        value: 'subs_plan_type',
        menus: [
          { label: 'Monthly', value: 'monthly' },
          { label: 'Annually', value: 'yearly' },
        ],
      },
      {
        name: 'Subs Status',
        value: 'subs_status',
        menus: [
          { label: 'Active', value: 'active' },
          { label: 'Cancelled', value: 'cancelled' },
        ],
      },
      {
        name: 'Payment Status',
        value: 'subs',
        menus: [
          { label: 'All', value: 'none' },
          { label: 'Subscribed', value: 'subscriber' },
          { label: 'Paid', value: 'paid' },
        ],
      },
      {
        name: 'User Status',
        value: 'status',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Active', value: 'active' },
          { label: 'Inactive', value: 'inactive' },
        ],
      }
    ],
    onResetFilters: {
      date_range: {
        filter_label: 'date_range',
        value: {
          key: 'created_on',
          preset: 'ps_last_7_days_including_today'
        }
      },
      status: {
        filter_label: 'User Status',
        label: 'All',
        value: 'all'
      }
    }
  },
}

export const ABUSE_LOGS = {
  [CLIENTS.CLEAROUT.NAME]: {
    filters: [
      {
        name: 'Action',
        value: 'task',
        menus: [
          { label: 'All', value: 'signup_abuse' },
          { label: 'Signup Abuse', value: 'signup_abuse' },
          { label: 'Signup Activation Abuse', value: 'signup_activation_abuse' },
          { label: 'Email Edit Abuse', value: 'email_edit_abuse' },
        ],
      },
      {
        name: 'Type',
        value: 'name',
        menus: [
          { label: 'IP Blocked', value: 'ip_blocked' },
          { label: 'IP Unblocked', value: 'ip_unblocked' },
          { label: 'Domain Blocked', value: 'domain_blocked' },
          { label: 'Domain Unblocked', value: 'domain_unblocked' },
          { label: 'Email Whitelisted', value: 'email_whitelisted' },
          { label: 'Email Delisted', value: 'email_delisted' },
          { label: 'Email Denied', value: 'email_denied' },
          { label: 'Matomo Blocked', value: 'matomo_blocked' },
          { label: 'Country Blocked', value: 'country_blocked' },
          { label: 'Captcha Blocked', value: 'captcha_blocked' },
        ],
      },
    ],
    onResetFilters: {
      date_range: {
        filter_label: 'date_range',
        value: {
          key: 'created_on',
          preset: 'ps_last_7_days_including_today'
        }
      },
      task: {
        filter_label: 'Action',
        label: 'All',
        value: null
      }
    }
  },
  [CLIENTS.CLEAROUTPHONE.NAME]: {
    filters: [
      {
        name: 'Action',
        value: 'task',
        menus: [
          { label: 'All', value: 'signup_abuse' },
          { label: 'Signup Abuse', value: 'signup_abuse' },
          { label: 'Signup Activation Abuse', value: 'signup_activation_abuse' },
          { label: 'Email Edit Abuse', value: 'email_edit_abuse' },
        ],
      },
      {
        name: 'Type',
        value: 'name',
        menus: [
          { label: 'IP Blocked', value: 'ip_blocked' },
          { label: 'IP Unblocked', value: 'ip_unblocked' },
          { label: 'Domain Blocked', value: 'domain_blocked' },
          { label: 'Domain Unblocked', value: 'domain_unblocked' },
          { label: 'Email Whitelisted', value: 'email_whitelisted' },
          { label: 'Email Delisted', value: 'email_delisted' },
          { label: 'Email Denied', value: 'email_denied' },
          { label: 'Matomo Blocked', value: 'matomo_blocked' },
          { label: 'Country Blocked', value: 'country_blocked' },
          { label: 'Captcha Blocked', value: 'captcha_blocked' },
        ],
      },
    ],
    onResetFilters: {
      date_range: {
        filter_label: 'date_range',
        value: {
          key: 'created_on',
          preset: 'ps_last_7_days_including_today'
        }
      },
      task: {
        filter_label: 'Action',
        label: 'All',
        value: null
      }
    }
  },
}

export const ABUSE_UPDATE_HISTORY = {
  [CLIENTS.CLEAROUT.NAME]: {
    filters: [
      {
        name: 'Tasks',
        value: 'task',
        menus: [
          { label: 'All', value: null },
          { label: 'Abuse Limiter Updates', value: 'abuse_limiter_updated' },
          { label: 'Abuse List Updates', value: 'abuse_list_updated' },
        ],
      },
      {
        name: 'Name',
        value: 'name',
        menus: [
          { label: 'IP Blocked', value: 'ip_blocked' },
          { label: 'IP Unblocked', value: 'ip_unblocked' },
          { label: 'Domain Blocked', value: 'domain_blocked' },
          { label: 'Domain Unblocked', value: 'domain_unblocked' },
          { label: 'Email Whitelisted', value: 'email_whitelisted' },
          { label: 'Email Delisted', value: 'email_delisted' },
          { label: 'Country Blocked', value: 'country_blocked' },
          { label: 'Country Unblocked', value: 'country_unblocked' },
        ],
      },
    ],
    onResetFilters: {
      date_range: {
        filter_label: 'date_range',
        value: {
          key: 'created_on',
          preset: 'ps_last_7_days_including_today',
        }
      },
      task: {
        filter_label: 'Action',
        label: 'All',
        value: null
      }
    }
  },
  [CLIENTS.CLEAROUTPHONE.NAME]: {
    filters: [
      {
        name: 'Tasks',
        value: 'task',
        menus: [
          { label: 'All', value: null },
          { label: 'Abuse Limiter Updates', value: 'abuse_limiter_updated' },
          { label: 'Abuse List Updates', value: 'abuse_list_updated' },
        ],
      },
      {
        name: 'Name',
        value: 'name',
        menus: [
          { label: 'IP Blocked', value: 'ip_blocked' },
          { label: 'IP Unblocked', value: 'ip_unblocked' },
          { label: 'Domain Blocked', value: 'domain_blocked' },
          { label: 'Domain Unblocked', value: 'domain_unblocked' },
          { label: 'Email Whitelisted', value: 'email_whitelisted' },
          { label: 'Email Delisted', value: 'email_delisted' },
          { label: 'Country Blocked', value: 'country_blocked' },
          { label: 'Country Unblocked', value: 'country_unblocked' },
        ],
      },
    ],
    onResetFilters: {
      date_range: {
        filter_label: 'date_range',
        value: {
          key: 'created_on',
          preset: 'ps_last_7_days_including_today',
        }
      },
      task: {
        filter_label: 'Action',
        label: 'All',
        value: null
      }
    }
  },
}

export const MEMBERS_LIST = {
  [CLIENTS.CLEAROUT.NAME]: {
    filters: [
      {
        name: 'User Status',
        value: 'status',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Active', value: 'active' },
          { label: 'InActive', value: 'inactive' },
          { label: 'Yet to Activate', value: 'new' },
          { label: 'Removed', value: 'removed' },
        ],
      },
      {
        name: 'Role',
        value: 'user_role',
        menus: [
          { label: 'All', value: 'none' },
          { label: 'Owner', value: 'owner' },
          { label: 'Manager', value: 'admin' },
          { label: 'Executive', value: 'analyst' },
        ],
      },
    ],
    onResetFilters: {
      date_range: {
        filter_label: 'date_range',
        value: {
          key: 'created_on',
          preset: 'ps_last_7_days_including_today'
        }
      },
      status: {
        filter_label: 'User Status',
        label: 'All',
        value: 'all'
      }
    }
  },
  [CLIENTS.CLEAROUTPHONE.NAME]: {
    filters: [
      {
        name: 'Action',
        value: 'task',
        menus: [
          { label: 'All', value: 'signup_abuse' },
          { label: 'Signup Abuse', value: 'signup_abuse' },
          { label: 'Signup Activation Abuse', value: 'signup_activation_abuse' },
          { label: 'Email Edit Abuse', value: 'email_edit_abuse' },
        ],
      },
      {
        name: 'Type',
        value: 'name',
        menus: [
          { label: 'IP Blocked', value: 'ip_blocked' },
          { label: 'IP Unblocked', value: 'ip_unblocked' },
          { label: 'Domain Blocked', value: 'domain_blocked' },
          { label: 'Domain Unblocked', value: 'domain_unblocked' },
          { label: 'Email Whitelisted', value: 'email_whitelisted' },
          { label: 'Email Delisted', value: 'email_delisted' },
          { label: 'Email Denied', value: 'email_denied' },
          { label: 'Matomo Blocked', value: 'matomo_blocked' },
          { label: 'Country Blocked', value: 'country_blocked' },
          { label: 'Captcha Blocked', value: 'captcha_blocked' },
        ],
      },
    ],
    onResetFilters: {
      date_range: {
        filter_label: 'date_range',
        value: {
          key: 'created_on',
          preset: 'ps_last_7_days_including_today'
        }
      },
      task: {
        filter_label: 'Action',
        label: 'All',
        value: null
      }
    }
  },
  [CLIENTS.SPOKESLY_CLEAROUT.NAME]: {
    filters: [
      {
        name: 'User Status',
        value: 'status',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Active', value: 'active' },
          { label: 'InActive', value: 'inactive' },
          { label: 'Yet to Activate', value: 'new' },
          { label: 'Removed', value: 'removed' },
        ],
      },
      {
        name: 'Role',
        value: 'user_role',
        menus: [
          { label: 'All', value: 'none' },
          { label: 'Owner', value: 'owner' },
          { label: 'Manager', value: 'admin' },
          { label: 'Executive', value: 'analyst' },
        ],
      },
    ],
    onResetFilters: {
      date_range: {
        filter_label: 'date_range',
        value: {
          key: 'created_on',
          preset: 'ps_last_7_days_including_today'
        }
      },
      status: {
        filter_label: 'User Status',
        label: 'All',
        value: 'all'
      }
    }
  },
  [CLIENTS.EU_CLEAROUT.NAME]: {
    filters: [
      {
        name: 'User Status',
        value: 'status',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Active', value: 'active' },
          { label: 'InActive', value: 'inactive' },
          { label: 'Yet to Activate', value: 'new' },
          { label: 'Removed', value: 'removed' },
        ],
      },
      {
        name: 'Role',
        value: 'user_role',
        menus: [
          { label: 'All', value: 'none' },
          { label: 'Owner', value: 'owner' },
          { label: 'Manager', value: 'admin' },
          { label: 'Executive', value: 'analyst' },
        ],
      },
    ],
    onResetFilters: {
      date_range: {
        filter_label: 'date_range',
        value: {
          key: 'created_on',
          preset: 'ps_last_7_days_including_today'
        }
      },
      status: {
        filter_label: 'User Status',
        label: 'All',
        value: 'all'
      }
    }
  },
  [CLIENTS.EP_CLEAROUT.NAME]: {
    filters: [
      {
        name: 'User Status',
        value: 'status',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Active', value: 'active' },
          { label: 'InActive', value: 'inactive' },
          { label: 'Yet to Activate', value: 'new' },
          { label: 'Removed', value: 'removed' },
        ],
      },
      {
        name: 'Role',
        value: 'user_role',
        menus: [
          { label: 'All', value: 'none' },
          { label: 'Owner', value: 'owner' },
          { label: 'Manager', value: 'admin' },
          { label: 'Executive', value: 'analyst' },
        ],
      },
    ],
    onResetFilters: {
      date_range: {
        filter_label: 'date_range',
        value: {
          key: 'created_on',
          preset: 'ps_last_7_days_including_today'
        }
      },
      status: {
        filter_label: 'User Status',
        label: 'All',
        value: 'all'
      }
    }
  },
  [CLIENTS.PEPIPOST_CLEAROUT.NAME]: {
    filters: [
      {
        name: 'User Status',
        value: 'status',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Active', value: 'active' },
          { label: 'InActive', value: 'inactive' },
          { label: 'Yet to Activate', value: 'new' },
          { label: 'Removed', value: 'removed' },
        ],
      },
      {
        name: 'Role',
        value: 'user_role',
        menus: [
          { label: 'All', value: 'none' },
          { label: 'Owner', value: 'owner' },
          { label: 'Manager', value: 'admin' },
          { label: 'Executive', value: 'analyst' },
        ],
      },
    ],
    onResetFilters: {
      date_range: {
        filter_label: 'date_range',
        value: {
          key: 'created_on',
          preset: 'ps_last_7_days_including_today'
        }
      },
      status: {
        filter_label: 'User Status',
        label: 'All',
        value: 'all'
      }
    }
  },
  [CLIENTS.CAMS_CLEAROUT.NAME]: {
    filters: [
      {
        name: 'User Status',
        value: 'status',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Active', value: 'active' },
          { label: 'InActive', value: 'inactive' },
          { label: 'Yet to Activate', value: 'new' },
          { label: 'Removed', value: 'removed' },
        ],
      },
      {
        name: 'Role',
        value: 'user_role',
        menus: [
          { label: 'All', value: 'none' },
          { label: 'Owner', value: 'owner' },
          { label: 'Manager', value: 'admin' },
          { label: 'Executive', value: 'analyst' },
        ],
      },
    ],
    onResetFilters: {
      date_range: {
        filter_label: 'date_range',
        value: {
          key: 'created_on',
          preset: 'ps_last_7_days_including_today'
        }
      },
      status: {
        filter_label: 'User Status',
        label: 'All',
        value: 'all'
      }
    }
  },
}


export const ANALYTICS = {
  [CLIENTS.CLEAROUT.NAME]: {
    filters: [
      {
        name: 'Date',
        value: 'date_range',
        menus: {
          ps_today: {
            label: 'Today',
            value: 'ps_today'
          },
          ps_yesterday: {
            label: 'Yesterday',
            value: 'ps_yesterday'
          },
          ps_last_7_days_including_today: {
            label: 'Last 7 days (Including Today)',
            value: 'ps_last_7_days_including_today'
          },
          ps_this_week_mon_today: {
            label: 'This week (Mon - Today)',
            value: 'ps_this_week_mon_today'
          },
          ps_last_week_mon_sun: {
            label: 'Last week (Mon - Sun)',
            value: 'ps_last_week_mon_sun'
          },
          ps_this_month: {
            label: 'This Month',
            value: 'ps_this_month'
          },
          ps_last_month: {
            label: 'Last Month',
            value: 'ps_last_month'
          },
          ps_last_30_days: {
            label: 'Last 30 Days',
            value: 'ps_last_30_days'
          },
          ps_this_year: {
            label: 'This Year',
            value: 'ps_this_year'
          },
          ps_last_year: {
            label: 'Last Year',
            value: 'ps_last_year'
          },
          // custom_date: {
          //   label: 'Custom Range',
          //   value: 'custom_date'
          // }
        }
      }
    ],
    onResetFilters: {
      date_range: {
        filter_label: 'Date',
        label: 'Last 7 days (Including Today)',
        value: 'ps_last_7_days_including_today'
      },
    }
  },
  [CLIENTS.SPOKESLY_CLEAROUT.NAME]: {
    filters: [
      {
        name: 'Date',
        value: 'date_range',
        menus: {
          ps_today: {
            label: 'Today',
            value: 'ps_today'
          },
          ps_yesterday: {
            label: 'Yesterday',
            value: 'ps_yesterday'
          },
          ps_last_7_days_including_today: {
            label: 'Last 7 days (Including Today)',
            value: 'ps_last_7_days_including_today'
          },
          ps_this_week_mon_today: {
            label: 'This week (Mon - Today)',
            value: 'ps_this_week_mon_today'
          },
          ps_last_week_mon_sun: {
            label: 'Last week (Mon - Sun)',
            value: 'ps_last_week_mon_sun'
          },
          ps_this_month: {
            label: 'This Month',
            value: 'ps_this_month'
          },
          ps_last_month: {
            label: 'Last Month',
            value: 'ps_last_month'
          },
          ps_last_30_days: {
            label: 'Last 30 Days',
            value: 'ps_last_30_days'
          },
          ps_this_year: {
            label: 'This Year',
            value: 'ps_this_year'
          },
          ps_last_year: {
            label: 'Last Year',
            value: 'ps_last_year'
          },
          // custom_date: {
          //   label: 'Custom Range',
          //   value: 'custom_date'
          // }
        }
      }
    ],
    onResetFilters: {
      date_range: {
        filter_label: 'Date',
        label: 'Last 7 days (Including Today)',
        value: 'ps_last_7_days_including_today'
      },
    }
  },
  [CLIENTS.EU_CLEAROUT.NAME]: {
    filters: [
      {
        name: 'Date',
        value: 'date_range',
        menus: {
          ps_today: {
            label: 'Today',
            value: 'ps_today'
          },
          ps_yesterday: {
            label: 'Yesterday',
            value: 'ps_yesterday'
          },
          ps_last_7_days_including_today: {
            label: 'Last 7 days (Including Today)',
            value: 'ps_last_7_days_including_today'
          },
          ps_this_week_mon_today: {
            label: 'This week (Mon - Today)',
            value: 'ps_this_week_mon_today'
          },
          ps_last_week_mon_sun: {
            label: 'Last week (Mon - Sun)',
            value: 'ps_last_week_mon_sun'
          },
          ps_this_month: {
            label: 'This Month',
            value: 'ps_this_month'
          },
          ps_last_month: {
            label: 'Last Month',
            value: 'ps_last_month'
          },
          ps_last_30_days: {
            label: 'Last 30 Days',
            value: 'ps_last_30_days'
          },
          ps_this_year: {
            label: 'This Year',
            value: 'ps_this_year'
          },
          ps_last_year: {
            label: 'Last Year',
            value: 'ps_last_year'
          },
          // custom_date: {
          //   label: 'Custom Range',
          //   value: 'custom_date'
          // }
        }
      }
    ],
    onResetFilters: {
      date_range: {
        filter_label: 'Date',
        label: 'Last 7 days (Including Today)',
        value: 'ps_last_7_days_including_today'
      },
    }
  },
  [CLIENTS.EP_CLEAROUT.NAME]: {
    filters: [
      {
        name: 'Date',
        value: 'date_range',
        menus: {
          ps_today: {
            label: 'Today',
            value: 'ps_today'
          },
          ps_yesterday: {
            label: 'Yesterday',
            value: 'ps_yesterday'
          },
          ps_last_7_days_including_today: {
            label: 'Last 7 days (Including Today)',
            value: 'ps_last_7_days_including_today'
          },
          ps_this_week_mon_today: {
            label: 'This week (Mon - Today)',
            value: 'ps_this_week_mon_today'
          },
          ps_last_week_mon_sun: {
            label: 'Last week (Mon - Sun)',
            value: 'ps_last_week_mon_sun'
          },
          ps_this_month: {
            label: 'This Month',
            value: 'ps_this_month'
          },
          ps_last_month: {
            label: 'Last Month',
            value: 'ps_last_month'
          },
          ps_last_30_days: {
            label: 'Last 30 Days',
            value: 'ps_last_30_days'
          },
          ps_this_year: {
            label: 'This Year',
            value: 'ps_this_year'
          },
          ps_last_year: {
            label: 'Last Year',
            value: 'ps_last_year'
          },
          // custom_date: {
          //   label: 'Custom Range',
          //   value: 'custom_date'
          // }
        }
      }
    ],
    onResetFilters: {
      date_range: {
        filter_label: 'Date',
        label: 'Last 7 days (Including Today)',
        value: 'ps_last_7_days_including_today'
      },
    }
  },
  [CLIENTS.PEPIPOST_CLEAROUT.NAME]: {
    filters: [
      {
        name: 'Date',
        value: 'date_range',
        menus: {
          ps_today: {
            label: 'Today',
            value: 'ps_today'
          },
          ps_yesterday: {
            label: 'Yesterday',
            value: 'ps_yesterday'
          },
          ps_last_7_days_including_today: {
            label: 'Last 7 days (Including Today)',
            value: 'ps_last_7_days_including_today'
          },
          ps_this_week_mon_today: {
            label: 'This week (Mon - Today)',
            value: 'ps_this_week_mon_today'
          },
          ps_last_week_mon_sun: {
            label: 'Last week (Mon - Sun)',
            value: 'ps_last_week_mon_sun'
          },
          ps_this_month: {
            label: 'This Month',
            value: 'ps_this_month'
          },
          ps_last_month: {
            label: 'Last Month',
            value: 'ps_last_month'
          },
          ps_last_30_days: {
            label: 'Last 30 Days',
            value: 'ps_last_30_days'
          },
          ps_this_year: {
            label: 'This Year',
            value: 'ps_this_year'
          },
          ps_last_year: {
            label: 'Last Year',
            value: 'ps_last_year'
          },
          // custom_date: {
          //   label: 'Custom Range',
          //   value: 'custom_date'
          // }
        }
      }
    ],
    onResetFilters: {
      date_range: {
        filter_label: 'Date',
        label: 'Last 7 days (Including Today)',
        value: 'ps_last_7_days_including_today'
      },
    }
  },
  [CLIENTS.CAMS_CLEAROUT.NAME]: {
    filters: [
      {
        name: 'Date',
        value: 'date_range',
        menus: {
          ps_today: {
            label: 'Today',
            value: 'ps_today'
          },
          ps_yesterday: {
            label: 'Yesterday',
            value: 'ps_yesterday'
          },
          ps_last_7_days_including_today: {
            label: 'Last 7 days (Including Today)',
            value: 'ps_last_7_days_including_today'
          },
          ps_this_week_mon_today: {
            label: 'This week (Mon - Today)',
            value: 'ps_this_week_mon_today'
          },
          ps_last_week_mon_sun: {
            label: 'Last week (Mon - Sun)',
            value: 'ps_last_week_mon_sun'
          },
          ps_this_month: {
            label: 'This Month',
            value: 'ps_this_month'
          },
          ps_last_month: {
            label: 'Last Month',
            value: 'ps_last_month'
          },
          ps_last_30_days: {
            label: 'Last 30 Days',
            value: 'ps_last_30_days'
          },
          ps_this_year: {
            label: 'This Year',
            value: 'ps_this_year'
          },
          ps_last_year: {
            label: 'Last Year',
            value: 'ps_last_year'
          },
          // custom_date: {
          //   label: 'Custom Range',
          //   value: 'custom_date'
          // }
        }
      }
    ],
    onResetFilters: {
      date_range: {
        filter_label: 'Date',
        label: 'Last 7 days (Including Today)',
        value: 'ps_last_7_days_including_today'
      },
    }
  },
}

export const PRODUCT_LIST_PAGE = {
  [CLIENTS.CLEAROUT.NAME]: {
    filters: [
      {
        name: 'Billed',
        value: 'renewal_interval',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Monthly', value: 'monthly' },
          { label: 'Annually', value: 'yearly' },
          { label: 'One Time', value: 'one_time' },
        ],
      },
      {
        name: 'Product Status',
        value: 'status',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Active', value: 'active' },
          { label: 'Inactive', value: 'inactive' },
        ],
      },
      {
        name: 'Currency',
        value: 'currency',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'INR', value: 'inr' },
          { label: 'USD', value: 'usd' },
        ],
      },
      {
        name: 'Type',
        value: 'type',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Plan', value: 'plan' },
          { label: 'Addon', value: 'addon' },
        ],
      }
    ],
    onResetFilters: {
      renewal_interval: {
        filter_label: 'Billed',
        label: 'All',
        value: 'all'
      },
      status: {
        filter_label: 'Product Status',
        label: 'All',
        value: 'all'
      },
      currency: {
        filter_label: 'Currency',
        label: 'All',
        value: 'all'
      }
    }
  },
  [CLIENTS.SPOKESLY_CLEAROUT.NAME]: {
    filters: [
      {
        name: 'Billed',
        value: 'renewal_interval',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Monthly', value: 'monthly' },
          { label: 'Annually', value: 'yearly' },
          { label: 'One Time', value: 'one_time' },
        ],
      },
      {
        name: 'Product Status',
        value: 'status',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Active', value: 'active' },
          { label: 'Inactive', value: 'inactive' },
        ],
      },
      {
        name: 'Currency',
        value: 'currency',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'INR', value: 'inr' },
          { label: 'USD', value: 'usd' },
        ],
      },
      {
        name: 'Type',
        value: 'type',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Plan', value: 'plan' },
          { label: 'Addon', value: 'addon' },
        ],
      }
    ],
    onResetFilters: {
      renewal_interval: {
        filter_label: 'Billed',
        label: 'All',
        value: 'all'
      },
      status: {
        filter_label: 'Product Status',
        label: 'All',
        value: 'all'
      },
      currency: {
        filter_label: 'Currency',
        label: 'All',
        value: 'all'
      }
    }
  },
  [CLIENTS.EU_CLEAROUT.NAME]: {
    filters: [
      {
        name: 'Billed',
        value: 'renewal_interval',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Monthly', value: 'monthly' },
          { label: 'Annually', value: 'yearly' },
          { label: 'One Time', value: 'one_time' },
        ],
      },
      {
        name: 'Product Status',
        value: 'status',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Active', value: 'active' },
          { label: 'Inactive', value: 'inactive' },
        ],
      },
      {
        name: 'Currency',
        value: 'currency',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'INR', value: 'inr' },
          { label: 'USD', value: 'usd' },
        ],
      },
      {
        name: 'Type',
        value: 'type',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Plan', value: 'plan' },
          { label: 'Addon', value: 'addon' },
        ],
      }
    ],
    onResetFilters: {
      renewal_interval: {
        filter_label: 'Billed',
        label: 'All',
        value: 'all'
      },
      status: {
        filter_label: 'Product Status',
        label: 'All',
        value: 'all'
      },
      currency: {
        filter_label: 'Currency',
        label: 'All',
        value: 'all'
      }
    }
  },
  [CLIENTS.EP_CLEAROUT.NAME]: {
    filters: [
      {
        name: 'Billed',
        value: 'renewal_interval',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Monthly', value: 'monthly' },
          { label: 'Annually', value: 'yearly' },
          { label: 'One Time', value: 'one_time' },
        ],
      },
      {
        name: 'Product Status',
        value: 'status',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Active', value: 'active' },
          { label: 'Inactive', value: 'inactive' },
        ],
      },
      {
        name: 'Currency',
        value: 'currency',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'INR', value: 'inr' },
          { label: 'USD', value: 'usd' },
        ],
      },
      {
        name: 'Type',
        value: 'type',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Plan', value: 'plan' },
          { label: 'Addon', value: 'addon' },
        ],
      }
    ],
    onResetFilters: {
      renewal_interval: {
        filter_label: 'Billed',
        label: 'All',
        value: 'all'
      },
      status: {
        filter_label: 'Product Status',
        label: 'All',
        value: 'all'
      },
      currency: {
        filter_label: 'Currency',
        label: 'All',
        value: 'all'
      }
    }
  },
  [CLIENTS.PEPIPOST_CLEAROUT.NAME]: {
    filters: [
      {
        name: 'Billed',
        value: 'renewal_interval',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Monthly', value: 'monthly' },
          { label: 'Annually', value: 'yearly' },
          { label: 'One Time', value: 'one_time' },
        ],
      },
      {
        name: 'Product Status',
        value: 'status',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Active', value: 'active' },
          { label: 'Inactive', value: 'inactive' },
        ],
      },
      {
        name: 'Currency',
        value: 'currency',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'INR', value: 'inr' },
          { label: 'USD', value: 'usd' },
        ],
      },
      {
        name: 'Type',
        value: 'type',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Plan', value: 'plan' },
          { label: 'Addon', value: 'addon' },
        ],
      }
    ],
    onResetFilters: {
      renewal_interval: {
        filter_label: 'Billed',
        label: 'All',
        value: 'all'
      },
      status: {
        filter_label: 'Product Status',
        label: 'All',
        value: 'all'
      },
      currency: {
        filter_label: 'Currency',
        label: 'All',
        value: 'all'
      }
    }
  },
  [CLIENTS.CAMS_CLEAROUT.NAME]: {
    filters: [
      {
        name: 'Billed',
        value: 'renewal_interval',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Monthly', value: 'monthly' },
          { label: 'Annually', value: 'yearly' },
          { label: 'One Time', value: 'one_time' },
        ],
      },
      {
        name: 'Product Status',
        value: 'status',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Active', value: 'active' },
          { label: 'Inactive', value: 'inactive' },
        ],
      },
      {
        name: 'Currency',
        value: 'currency',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'INR', value: 'inr' },
          { label: 'USD', value: 'usd' },
        ],
      },
      {
        name: 'Type',
        value: 'type',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Plan', value: 'plan' },
          { label: 'Addon', value: 'addon' },
        ],
      }
    ],
    onResetFilters: {
      renewal_interval: {
        filter_label: 'Billed',
        label: 'All',
        value: 'all'
      },
      status: {
        filter_label: 'Product Status',
        label: 'All',
        value: 'all'
      },
      currency: {
        filter_label: 'Currency',
        label: 'All',
        value: 'all'
      }
    }
  },
}

export const PLANS_TAB = {
  [CLIENTS.CLEAROUT.NAME]: {
    filters: [
      {
        name: 'Billed',
        value: 'billed',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Monthly', value: 'monthly' },
          { label: 'Annually', value: 'yearly' },
          { label: 'One Time', value: 'one_time' },
        ],
      },
      {
        name: 'Subscription Status',
        value: 'subscription_status',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Active', value: 'active' },
          { label: 'Cancelled', value: 'cancelled' },
          // { label: 'Inactive', value: 'inactive' },
        ],
      },
      {
        name: 'Currency',
        value: 'currency',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'INR', value: 'inr' },
          { label: 'USD', value: 'usd' },
        ],
      },
      {
        name: 'Product Type',
        value: 'product_type',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Plan', value: 'plan' },
          { label: 'Addon', value: 'addon' },
        ],
      },
      {
        name: 'Customized',
        value: 'custom',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' },
        ],
      },
      {
        name: 'Next Renewal On',
        value: 'product_renewal',
        menus: {
          all: {
            lable: 'All',
            value: 'all'
          },
          ps_today: {
            label: 'Today',
            value: 'ps_today'
          },
          ps_tomorrow: {
            label: 'Tomorrow',
            value: 'ps_tomorrow'
          },
          ps_next_7_days_including_today: {
            label: 'Next 7 days (Including Today)',
            value: 'ps_next_7_days_including_today'
          },
          ps_this_week_today_sun: {
            label: 'This week (Today - Sunday)',
            value: 'ps_this_week_today_sun'
          },
          ps_next_week_mon_sun: {
            label: 'Next week (Mon - Sun)',
            value: 'ps_next_week_mon_sun'
          },
          ps_this_whole_month: {
            label: 'This Month',
            value: 'ps_this_whole_month'
          },
          ps_next_month: {
            label: 'Next Month',
            value: 'ps_next_month'
          },
          ps_next_30_days: {
            label: 'Next 30 Days',
            value: 'ps_next_30_days'
          },
          ps_this_whole_year: {
            label: 'This Year',
            value: 'ps_this_whole_year'
          },
          ps_next_year: {
            label: 'Next Year',
            value: 'ps_next_year'
          }
        }
      }
    ],
    onResetFilters: {
      billed: {
        filter_label: 'Billed',
        label: 'All',
        value: 'all'
      },
      currency: {
        filter_label: 'Currency',
        label: 'All',
        value: 'all'
      },
      subscription_status: {
        filter_label: 'Subscription Status',
        label: 'All',
        value: 'all'
      }
    }
  },
  [CLIENTS.SPOKESLY_CLEAROUT.NAME]: {
    filters: [
      {
        name: 'Billed',
        value: 'billed',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Monthly', value: 'monthly' },
          { label: 'Annually', value: 'yearly' },
          { label: 'One Time', value: 'one_time' },
        ],
      },
      {
        name: 'Subscription Status',
        value: 'subscription_status',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Active', value: 'active' },
          { label: 'Cancelled', value: 'cancelled' },
          // { label: 'Inactive', value: 'inactive' },
        ],
      },
      {
        name: 'Currency',
        value: 'currency',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'INR', value: 'inr' },
          { label: 'USD', value: 'usd' },
        ],
      },
      {
        name: 'Product Type',
        value: 'product_type',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Plan', value: 'plan' },
          { label: 'Addon', value: 'addon' },
        ],
      },
      {
        name: 'Customized',
        value: 'custom',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' },
        ],
      },
      {
        name: 'Next Renewal On',
        value: 'product_renewal',
        menus: {
          all: {
            lable: 'All',
            value: 'all'
          },
          ps_today: {
            label: 'Today',
            value: 'ps_today'
          },
          ps_tomorrow: {
            label: 'Tomorrow',
            value: 'ps_tomorrow'
          },
          ps_next_7_days_including_today: {
            label: 'Next 7 days (Including Today)',
            value: 'ps_next_7_days_including_today'
          },
          ps_this_week_today_sun: {
            label: 'This week (Today - Sunday)',
            value: 'ps_this_week_today_sun'
          },
          ps_next_week_mon_sun: {
            label: 'Next week (Mon - Sun)',
            value: 'ps_next_week_mon_sun'
          },
          ps_this_whole_month: {
            label: 'This Month',
            value: 'ps_this_whole_month'
          },
          ps_next_month: {
            label: 'Next Month',
            value: 'ps_next_month'
          },
          ps_next_30_days: {
            label: 'Next 30 Days',
            value: 'ps_next_30_days'
          },
          ps_this_whole_year: {
            label: 'This Year',
            value: 'ps_this_whole_year'
          },
          ps_next_year: {
            label: 'Next Year',
            value: 'ps_next_year'
          }
        }
      }
    ],
    onResetFilters: {
      billed: {
        filter_label: 'Billed',
        label: 'All',
        value: 'all'
      },
      currency: {
        filter_label: 'Currency',
        label: 'All',
        value: 'all'
      },
      subscription_status: {
        filter_label: 'Subscription Status',
        label: 'All',
        value: 'all'
      }
    }
  },
  [CLIENTS.EU_CLEAROUT.NAME]: {
    filters: [
      {
        name: 'Billed',
        value: 'billed',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Monthly', value: 'monthly' },
          { label: 'Annually', value: 'yearly' },
          { label: 'One Time', value: 'one_time' },
        ],
      },
      {
        name: 'Subscription Status',
        value: 'subscription_status',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Active', value: 'active' },
          { label: 'Cancelled', value: 'cancelled' },
          // { label: 'Inactive', value: 'inactive' },
        ],
      },
      {
        name: 'Currency',
        value: 'currency',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'INR', value: 'inr' },
          { label: 'USD', value: 'usd' },
        ],
      },
      {
        name: 'Product Type',
        value: 'product_type',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Plan', value: 'plan' },
          { label: 'Addon', value: 'addon' },
        ],
      },
      {
        name: 'Customized',
        value: 'custom',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' },
        ],
      },
      {
        name: 'Next Renewal On',
        value: 'product_renewal',
        menus: {
          all: {
            lable: 'All',
            value: 'all'
          },
          ps_today: {
            label: 'Today',
            value: 'ps_today'
          },
          ps_tomorrow: {
            label: 'Tomorrow',
            value: 'ps_tomorrow'
          },
          ps_next_7_days_including_today: {
            label: 'Next 7 days (Including Today)',
            value: 'ps_next_7_days_including_today'
          },
          ps_this_week_today_sun: {
            label: 'This week (Today - Sunday)',
            value: 'ps_this_week_today_sun'
          },
          ps_next_week_mon_sun: {
            label: 'Next week (Mon - Sun)',
            value: 'ps_next_week_mon_sun'
          },
          ps_this_whole_month: {
            label: 'This Month',
            value: 'ps_this_whole_month'
          },
          ps_next_month: {
            label: 'Next Month',
            value: 'ps_next_month'
          },
          ps_next_30_days: {
            label: 'Next 30 Days',
            value: 'ps_next_30_days'
          },
          ps_this_whole_year: {
            label: 'This Year',
            value: 'ps_this_whole_year'
          },
          ps_next_year: {
            label: 'Next Year',
            value: 'ps_next_year'
          }
        }
      }
    ],
    onResetFilters: {
      billed: {
        filter_label: 'Billed',
        label: 'All',
        value: 'all'
      },
      currency: {
        filter_label: 'Currency',
        label: 'All',
        value: 'all'
      },
      subscription_status: {
        filter_label: 'Subscription Status',
        label: 'All',
        value: 'all'
      }
    }
  },
  [CLIENTS.EP_CLEAROUT.NAME]: {
    filters: [
      {
        name: 'Billed',
        value: 'billed',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Monthly', value: 'monthly' },
          { label: 'Annually', value: 'yearly' },
          { label: 'One Time', value: 'one_time' },
        ],
      },
      {
        name: 'Subscription Status',
        value: 'subscription_status',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Active', value: 'active' },
          { label: 'Cancelled', value: 'cancelled' },
          // { label: 'Inactive', value: 'inactive' },
        ],
      },
      {
        name: 'Currency',
        value: 'currency',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'INR', value: 'inr' },
          { label: 'USD', value: 'usd' },
        ],
      },
      {
        name: 'Product Type',
        value: 'product_type',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Plan', value: 'plan' },
          { label: 'Addon', value: 'addon' },
        ],
      },
      {
        name: 'Customized',
        value: 'custom',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' },
        ],
      },
      {
        name: 'Next Renewal On',
        value: 'product_renewal',
        menus: {
          all: {
            lable: 'All',
            value: 'all'
          },
          ps_today: {
            label: 'Today',
            value: 'ps_today'
          },
          ps_tomorrow: {
            label: 'Tomorrow',
            value: 'ps_tomorrow'
          },
          ps_next_7_days_including_today: {
            label: 'Next 7 days (Including Today)',
            value: 'ps_next_7_days_including_today'
          },
          ps_this_week_today_sun: {
            label: 'This week (Today - Sunday)',
            value: 'ps_this_week_today_sun'
          },
          ps_next_week_mon_sun: {
            label: 'Next week (Mon - Sun)',
            value: 'ps_next_week_mon_sun'
          },
          ps_this_whole_month: {
            label: 'This Month',
            value: 'ps_this_whole_month'
          },
          ps_next_month: {
            label: 'Next Month',
            value: 'ps_next_month'
          },
          ps_next_30_days: {
            label: 'Next 30 Days',
            value: 'ps_next_30_days'
          },
          ps_this_whole_year: {
            label: 'This Year',
            value: 'ps_this_whole_year'
          },
          ps_next_year: {
            label: 'Next Year',
            value: 'ps_next_year'
          }
        }
      }
    ],
    onResetFilters: {
      billed: {
        filter_label: 'Billed',
        label: 'All',
        value: 'all'
      },
      currency: {
        filter_label: 'Currency',
        label: 'All',
        value: 'all'
      },
      subscription_status: {
        filter_label: 'Subscription Status',
        label: 'All',
        value: 'all'
      }
    }
  },
  [CLIENTS.CAMS_CLEAROUT.NAME]: {
    filters: [
      {
        name: 'Billed',
        value: 'billed',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Monthly', value: 'monthly' },
          { label: 'Annually', value: 'yearly' },
          { label: 'One Time', value: 'one_time' },
        ],
      },
      {
        name: 'Subscription Status',
        value: 'subscription_status',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Active', value: 'active' },
          { label: 'Cancelled', value: 'cancelled' },
          // { label: 'Inactive', value: 'inactive' },
        ],
      },
      {
        name: 'Currency',
        value: 'currency',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'INR', value: 'inr' },
          { label: 'USD', value: 'usd' },
        ],
      },
      {
        name: 'Product Type',
        value: 'product_type',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Plan', value: 'plan' },
          { label: 'Addon', value: 'addon' },
        ],
      },
      {
        name: 'Customized',
        value: 'custom',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' },
        ],
      },
      {
        name: 'Next Renewal On',
        value: 'product_renewal',
        menus: {
          all: {
            lable: 'All',
            value: 'all'
          },
          ps_today: {
            label: 'Today',
            value: 'ps_today'
          },
          ps_tomorrow: {
            label: 'Tomorrow',
            value: 'ps_tomorrow'
          },
          ps_next_7_days_including_today: {
            label: 'Next 7 days (Including Today)',
            value: 'ps_next_7_days_including_today'
          },
          ps_this_week_today_sun: {
            label: 'This week (Today - Sunday)',
            value: 'ps_this_week_today_sun'
          },
          ps_next_week_mon_sun: {
            label: 'Next week (Mon - Sun)',
            value: 'ps_next_week_mon_sun'
          },
          ps_this_whole_month: {
            label: 'This Month',
            value: 'ps_this_whole_month'
          },
          ps_next_month: {
            label: 'Next Month',
            value: 'ps_next_month'
          },
          ps_next_30_days: {
            label: 'Next 30 Days',
            value: 'ps_next_30_days'
          },
          ps_this_whole_year: {
            label: 'This Year',
            value: 'ps_this_whole_year'
          },
          ps_next_year: {
            label: 'Next Year',
            value: 'ps_next_year'
          }
        }
      }
    ],
    onResetFilters: {
      billed: {
        filter_label: 'Billed',
        label: 'All',
        value: 'all'
      },
      currency: {
        filter_label: 'Currency',
        label: 'All',
        value: 'all'
      },
      subscription_status: {
        filter_label: 'Subscription Status',
        label: 'All',
        value: 'all'
      }
    }
  },
  [CLIENTS.PEPIPOST_CLEAROUT.NAME]: {
    filters: [
      {
        name: 'Billed',
        value: 'billed',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Monthly', value: 'monthly' },
          { label: 'Annually', value: 'yearly' },
          { label: 'One Time', value: 'one_time' },
        ],
      },
      {
        name: 'Subscription Status',
        value: 'subscription_status',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Active', value: 'active' },
          { label: 'Cancelled', value: 'cancelled' },
          // { label: 'Inactive', value: 'inactive' },
        ],
      },
      {
        name: 'Currency',
        value: 'currency',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'INR', value: 'inr' },
          { label: 'USD', value: 'usd' },
        ],
      },
      {
        name: 'Product Type',
        value: 'product_type',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Plan', value: 'plan' },
          { label: 'Addon', value: 'addon' },
        ],
      },
      {
        name: 'Customized',
        value: 'custom',
        menus: [
          { label: 'All', value: 'all' },
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' },
        ],
      },
      {
        name: 'Next Renewal On',
        value: 'product_renewal',
        menus: {
          all: {
            lable: 'All',
            value: 'all'
          },
          ps_today: {
            label: 'Today',
            value: 'ps_today'
          },
          ps_tomorrow: {
            label: 'Tomorrow',
            value: 'ps_tomorrow'
          },
          ps_next_7_days_including_today: {
            label: 'Next 7 days (Including Today)',
            value: 'ps_next_7_days_including_today'
          },
          ps_this_week_today_sun: {
            label: 'This week (Today - Sunday)',
            value: 'ps_this_week_today_sun'
          },
          ps_next_week_mon_sun: {
            label: 'Next week (Mon - Sun)',
            value: 'ps_next_week_mon_sun'
          },
          ps_this_whole_month: {
            label: 'This Month',
            value: 'ps_this_whole_month'
          },
          ps_next_month: {
            label: 'Next Month',
            value: 'ps_next_month'
          },
          ps_next_30_days: {
            label: 'Next 30 Days',
            value: 'ps_next_30_days'
          },
          ps_this_whole_year: {
            label: 'This Year',
            value: 'ps_this_whole_year'
          },
          ps_next_year: {
            label: 'Next Year',
            value: 'ps_next_year'
          }
        }
      }
    ],
    onResetFilters: {
      billed: {
        filter_label: 'Billed',
        label: 'All',
        value: 'all'
      },
      currency: {
        filter_label: 'Currency',
        label: 'All',
        value: 'all'
      },
      subscription_status: {
        filter_label: 'Subscription Status',
        label: 'All',
        value: 'all'
      }
    }
  },
}