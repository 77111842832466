import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: 0,
  name: 'User',
  pic: '',
  isLogged: false,
  isOnline: false,
  jwtToken: null,
  chatUserFetched: false,
  chatUserId: null
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      return Object.assign({}, state, {
        id: action.payload.user_id,
        name: action.payload.name,
        email: action.payload.email,
        role: action.payload.role,
        jwtToken: action.payload.token,
        session_id: action.payload.session_id,
        type: action.payload.type,
        isLogged: true,
        isOnline: true
      })
    },
    loginFailed: () => {
      return initialState
    },
    logoutSuccess: () => {
      return initialState
    }
  }
})

export const { loginSuccess, loginFailed, logoutSuccess } = userSlice.actions

export default userSlice.reducer;
