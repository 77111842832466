import React from 'react'
import { createBrowserRouter, Navigate } from 'react-router-dom'

import * as Constants from '../../helpers/constants'
import UserlistPage from '../user_list_page'
import MiscellaneousApi from '../miscellaneous/miscellaneous_api'
import ClearoutIndividualUserInfo from '../individual_user_info/clearout_individual_user_info'
// import ProductList from './../products/component/list'
// import CreateProduct from './../products/component/create'
import OrganizationInfo from '../org_info/org_info'
// import Coupons from '../coupons/coupons'
import OrgListPage from '../org_list_page'
// import ManageAbuse from '../abuse/manage_abuse'
// import UpdateHistory from '../update_history/abuse_update_history'
// import AbuseLogs from '../abuse_view_logs/abuse_view_logs'
import UserActivities from '../user_activities/user_activities'
import ServiceLists from '../service_list'
import MembersList from '../members_list/members_list'

const CLIENT = Constants.CLIENTS.EU_CLEAROUT.NAME

const clearoutEuropeRouter = createBrowserRouter([
  {
    id: 'dashboard-eu-route',
    path: CLIENT,
    children: [
      {
        id: 'dashboard-eu-users',
        path: 'users',
        element: <UserlistPage />
      },
      {
        id: 'dashboard-eu-ev-list',
        path: 'email_verifier_lists',
        element: <ServiceLists service={Constants.SERVICE_TYPES[CLIENT].EMAIL_VERIFIER} />
      },
      {
        id: 'dashboard-eu-org-members-list',
        path: 'org_members_list',
        element: <MembersList />
      },
      {
        id: 'eu-individual-user-info',
        path: 'individual_user_info',
        element: <ClearoutIndividualUserInfo />
      },
      {
        id: 'dashboard-eu-org-users',
        path: 'org_list',
        element: <OrgListPage />
      },
      {
        id: 'eu-dashboard-user-activities',
        path: 'individual_user_activity',
        element: <UserActivities />
      },
      {
        id: 'dashboard-eu-clearout-miscellaneous-api',
        path: 'miscellaneous_api',
        element: <MiscellaneousApi />
      },
      {
        id: 'clearout-eu-organization-info',
        path: 'organization_info',
        element: <OrganizationInfo />
      },
      {
        id: CLIENT + '-redirect',
        path: '*',
        element:
          <Navigate
            to={'users'}
            replace={true}
          />
      },

    ]
  },
])

export default clearoutEuropeRouter
