import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import _ from 'lodash'

import * as Constants from '../../helpers/constants'
import showToastNotification from '../widgets/toastify';
import { ApiHelper } from '../../helpers/apihelper';

const initialState = {
  showModal: false,
  emailAddress: '',
  errors: {},
}

const RemoveEmailFromCache = ({ client }) => {
  const [state, setState] = useState(initialState)
  const userId = useSelector(state => state.user.id);

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setState(prevState => ({ ...prevState, emailAddress: emailValue }));
  }

  const validateEmail = (email) => {
    const emailRegex = Constants.REGEX.EMAIL_SYNTAX;
    const isValidEmail = emailRegex.test(email);

    if (!isValidEmail) {
      setState(prevState => ({
        ...prevState, errors: {
          emailAddress: 'Enter a valid email address',
        },
      }));

      setTimeout(() => {
        setState(prevState => ({
          ...prevState,
          errors: {
            emailAddress: null,
          },
        }));
      }, 5000);
    }

    return isValidEmail;
  };

  const handleSaveChanges = () => {
    const isValidEmail = validateEmail(state.emailAddress);

    if (!isValidEmail) {
      return;
    }

    const requestQueryParams = {
      email_address: state.emailAddress,
      user_id: userId,
    }

    let queryParams = _.pick(requestQueryParams, ApiHelper(client, 'REMOVE_EMAIL_FROM_CACHE').API_QUERY)


    ApiHelper(client, 'REMOVE_EMAIL_FROM_CACHE').API(client, queryParams)
      .then((response) => {
        if (response.status === Constants.CO_API.FAILED) {
          showToastNotification({
            message: 'Failed',
            isSuccess: false,
          });
        } else {
          showToastNotification({
            message: 'Removed Email from Cache',
            isSuccess: true,
          });
          setState((prevState) => ({
            ...prevState,
            showModal: false,
            emailAddress: '',
            emailValidation: { isValid: true, errorMessage: '', },
          }));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleShowModal = () => {
    setState(prevState => ({ ...prevState, showModal: true }));
  };

  const handleCloseModal = () => setState(initialState);

  return (
    <>
      <Button
        variant="outline-primary"
        onClick={handleShowModal}
      >
        Remove Email From Cache
      </Button>
      <Modal
        show={state.showModal}
        onHide={handleCloseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title className='fs-6 fw-bold'>Remove Email From Cache</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body-bg-color'>
          <Form>
            <div className='row'>
              <Form.Group
                className='d-flex'
              >
                <div className='col-md-3'>
                  <Form.Label className='w-100 py-2'>Enter Email:</Form.Label>
                </div>
                <div className='col-md-9'>
                  <Form.Control
                    type="email"
                    placeholder="Enter Email Address"
                    value={state.emailAddress}
                    onChange={handleEmailChange}
                    isInvalid={!!state.errors.emailAddress}
                  />
                  <Form.Control.Feedback type="invalid">
                    {state.errors.emailAddress}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-danger"
            onClick={handleSaveChanges}
            disabled={state.emailAddress.length === 0}
          >
            Remove
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RemoveEmailFromCache;
