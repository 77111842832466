export function prospectMetricFormatter(data) {
  const result = {
    total: data.total,
  };

  // Helper function to process nested properties
  function processCategory(category, suffix) {
    result[`total_${suffix}`] = category.total;

    for (const [key, value] of Object.entries(category)) {
      if (key !== 'total') {
        result[`${key}_${suffix}`] = value;
      }
    }
  }

  // Process emails and phones
  processCategory(data.emails, 'emails');
  processCategory(data.phones, 'phones');

  return result;
}
