import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { CompactTable } from '@table-library/react-table-library/compact';
import { useTheme } from '@table-library/react-table-library/theme';
import { getTheme } from '@table-library/react-table-library/baseline';
import { usePagination } from '@table-library/react-table-library/pagination';
import ClipboardCopy from '../widgets/clipboard_copy';
import { isMobile } from 'react-device-detect'
import ClickToCopy from '../widgets/copy_onclick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as Constants from '../../helpers/constants';
import * as Helpers from '../../helpers/helper';

import 'react-toggle/style.css';
import '../../static/css/abuse.css';

const initialState = {
  pageSize: 50,
  currentValue: {},
  currentPageIndex: 0,
};

const AbuseListTable = ({ getAbuseList, data }) => {
  const [state, setState] = useState(initialState);
  // const theme = useTheme(getTheme());
  // let maxRows = Math.min(data.nodes.length, state.pageSize) + 1 //header plus rows
  const tableTheme = useTheme(
    [
      getTheme(),
      {
        Table: `
        --data-table-library_grid-template-columns: 
          minmax(12rem, 1fr) // Created On
          minmax(10rem, 1fr) // Action
          minmax(8rem, 1fr) // Action Type
          minmax(18rem, 1fr) // Email
          minmax(10rem, 1fr) // User IP
          minmax(23rem, 1fr) // Reason
          minmax(12rem, 1fr); // Matomo Visitor ID
          margin: 0px;
          grid-auto-rows: minmax(max-content, max-content);
        `,
        HeaderCell: `
        height: fit-content;
      `,
        Row: `
          font-size:14px;
          height: max-content;
        `
      }
    ]
  )

  useEffect(() => {
    getAbuseList(state.currentPageIndex * state.pageSize, state.pageSize, null, { created_on: 'desc' });
  }, [getAbuseList, state.pageSize, state.currentPageIndex]);

  const getMatomoProfileUrlBasedOnVisitorId = (vid) => {
    const uri = `${MATOMO_URL}index.php?date=today&module=Widgetize&action=iframe&visitorId=${vid}&idSite=${MATOMO_SITE_ID}&moduleToWidgetize=Live&actionToWidgetize=getVisitorProfilePopup&period=year`;

    return (
      <div className='d-flex justify-content-between'>
        <ClickToCopy text={vid} />
        <a
          href={uri}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" />
        </a>
      </div>
    );
  };

  const COLUMNS = [
    { label: 'Created On', renderCell: (item) => <b>{Helpers.formatDate(item.created_on, 'DD-MMM-YYYY @ h:mm:ss a')}</b> },
    {
      label: 'Action',
      renderCell: (item) => {
        const action = Helpers.removeUnderscore(item.task);
        return Helpers.capitalizeFirstLetter(action);
      }
    },
    {
      label: 'Action Type', renderCell: (item) => {
        const actionType = Helpers.removeUnderscore(item.name);
        return Helpers.capitalizeFirstLetter(actionType)
      }
    },
    {
      label: 'Email', renderCell: (item) => (
        <div className='d-flex justify-content-between'>
          {item.email}
          {item.email ? (
            <ClipboardCopy textToCopy={item.email} />
          ) : '-'}
        </div>
      )
    },
    {
      label: 'User IP', renderCell: (item) => (
        <div className='d-flex justify-content-between'>
          {item.data.user_ip}
          {item.data.user_ip ? (
            <ClipboardCopy textToCopy={item.data.user_ip} />
          ) : null}
        </div>
      )
    },
    { label: 'Reason', renderCell: (item) => <p className='text-wrap p-0 m-0'>{item.data.reason}</p> },
    {
      label: 'Matomo Visitor ID',
      renderCell: (item) => {
        const vid = item.data?.visitor_id || '-';
        return (
          <div className='custom-word-wrap'>
            {vid === '-' ? vid : getMatomoProfileUrlBasedOnVisitorId(vid)}
          </div>
        );
      },
    }
  ];

  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setState((prevState) => ({ ...prevState, pageSize: newSize, currentPageIndex: 0 }));
    getAbuseList(0, newSize, null, { created_on: 'desc' });
  };

  const onPaginationChange = (action, newState) => {
    getAbuseList(newState.page * LIMIT, LIMIT, null, { created_on: 'desc' });
    setState((prevState) => ({
      ...prevState,
      currentPageIndex: newState.page,
    }));
  };

  const LIMIT = state.pageSize;
  const pagination = usePagination(
    data,
    {
      state: {
        page: state.currentPageIndex,
        size: LIMIT,
      },
      onChange: onPaginationChange,
    }
  );

  return (
    <>
      <div className='table-container'>
        {data.nodes.length === 0 ? (
          <table className='table table-bordered'>
            <thead className='fs-6'>
              <tr>
                {COLUMNS.map((column, index) => (
                  <th
                    className='fw-bold'
                    key={index}
                  >
                    {column.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  colSpan={COLUMNS.length}
                  className='text-center pt-4'
                >
                  No data found
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <CompactTable
            columns={COLUMNS}
            data={data}
            theme={tableTheme}
            layout={{ fixedHeader: true, horizontalScroll: !isMobile, custom: true }}
          />
        )}
      </div>
      <div className='d-flex justify-content-between pt-3'>
        <Form.Select
          value={state.pageSize}
          onChange={handlePageSizeChange}
          className='vertical-pagination'
        >
          {Constants.PAGE_SIZE.values.map((size) => (
            <option
              key={size}
              value={size}
            >
              {size}
            </option>
          ))}
        </Form.Select>
        <div>
          <button
            type='button'
            disabled={pagination.state.page === 0}
            onClick={() => pagination.fns.onSetPage(0)}
          >
            {'|<'}
          </button>
          <button
            type='button'
            disabled={pagination.state.page === 0}
            onClick={() => pagination.fns.onSetPage(pagination.state.page - 1)}
          >
            {'<'}
          </button>
          <button
            type='button'
            onClick={() => pagination.fns.onSetPage(pagination.state.page + 1)}
          >
            {'>'}
          </button>
        </div>
      </div>
    </>
  );
};

export default AbuseListTable;
