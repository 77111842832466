import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Dropdown, { MenuItem } from '@trendmicro/react-dropdown';
import { Badge, Button } from 'react-bootstrap';

import useOnClickOutside from '../../../hooks/on_click_outside_div';

import * as Constants from '../../../../helpers/constants';

import '@trendmicro/react-buttons/dist/react-buttons.css';
import '@trendmicro/react-dropdown/dist/react-dropdown.css';
import '../../../../static/css/search.css';

const Filters = ({
  setFilter,
  service,
  ...props
}) => {
  const location = useLocation();

  // State for filters
  const [selectedTypeFilter, setSelectedTypeFilter] = useState({
    label: 'All',
    value: 'all',
    filter_label: 'Source'
  });
  const [selectedDateRange, setSelectedDateRange] = useState({
    value: 'ps_last_7_days_including_today',
    label: 'Last 7 Days (Including Today)',
    filter_label: 'Created On'
  });
  const [enrichedOnFilter, setEnrichedOnFilter] = useState({});
  const [verifiedListFilter, setVerifiedListFilter] = useState({
    label: 'In Progress',
    value: 'in_progress',
    filter_label: 'Status'
  });
  const [processedListFilter, setProcessedListFilter] = useState({
    label: 'In Progress',
    value: 'in_progress',
    filter_label: 'Status'
  });
  const [enrichedStatusFilter, setEnrichedStatusFilter] = useState({
    label: 'In Progress',
    value: 'in_progress',
    filter_label: 'Status'
  });
  const [prospectTypeFilter, setProspectTypeFilter] = useState({
    label: 'All',
    value: 'all',
    filter_label: 'Type'
  });
  const filterState = {
    selectedTypeFilter,
    selectedDateRange,
    verifiedListFilter,
    processedListFilter,
    enrichedStatusFilter,
    prospectTypeFilter,
    enrichedOnFilter
  };
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  // Dropdown open state
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Ref for dropdown to handle clicks outside
  const filterDropdownRef = useRef(null);

  // Click outside to close dropdown
  useOnClickOutside(filterDropdownRef, () => setIsDropdownOpen(false));

  // Initial filter state
  const initialState = {
    selectedTypeFilter: {
      label: 'All',
      value: 'all',
      filter_label: 'Source'
    },
    selectedDateRange: {
      value: 'ps_last_7_days_including_today',
      label: 'Last 7 Days (Including Today)',
      filter_label: 'Created On'
    },
    verifiedListFilter: {
      label: 'In Progress',
      value: 'in_progress',
      filter_label: 'Status'
    },
    prospectTypeFilter: {
      label: 'All',
      value: 'all',
      filter_label: 'Type'
    },
    enrichedStatusFilter: {
      label: 'In Progress',
      value: 'in_progress',
      filter_label: 'Status'
    },
    showErrorMessage: false
  };

  // Set filter params based on selected filters
  const setParams = useCallback((newSelectedTypeFilter) => {
    let filters = {
      type: newSelectedTypeFilter.value,
      date_filter: { key: 'created_on', preset: selectedDateRange.value }
    };

    switch (verifiedListFilter.value) {
      case 'verified':
        filters.verified = 'verified';
        break;
      case 'non_verified':
        filters.verified = 'non_verified';
        break;
      case 'in_progress':
        filters.verified = 'in_progress';
        break;
      case 'cancelled':
        filters.verified = 'cancelled';
        break;
      default:
        filters.verified = null;
    }

    if (filters.type === 'all' || filters.type === '') {
      delete filters.type;
    }

    props.filterParams(filters);
  }, [selectedDateRange.value, verifiedListFilter.value, props,]);

  const updateFilter = useCallback((newFilterProperties) => {
    setFilter((prevFilter) => {
      // Create a new filters object, spreading the existing one
      const updatedFilters = { ...prevFilter.filters, ...newFilterProperties };

      // Remove any properties that have a value of `null`
      Object.keys(updatedFilters).forEach(key => {
        if (updatedFilters[key] === null) {
          delete updatedFilters[key];
        }
      });

      return {
        ...prevFilter,
        filters: updatedFilters,
        currentPageIndex: 0
      };
    });
  }, [setFilter]);

  // Selector for filters
  const onSelectSource = useCallback((type) => {
    if (type) {
      let selectedType = Constants.LIST_TYPES[type];
      selectedType.filter_label = 'Source';
      setShowErrorMessage(false);
      setSelectedTypeFilter(selectedType);
      setIsDropdownOpen(false);

      // If selected type is 'all', pass `null` to remove the `type` property
      const filterValue = selectedType.value === 'all' ? null : selectedType.value;
      updateFilter({ type: filterValue });
    }
  }, [updateFilter]);


  useEffect(() => {
    //setParams();
    let params = new URLSearchParams(location.search);
    if (params && params.get('source')) {
      onSelectSource(params.get('source'));
    }
  }, [onSelectSource, location.search, setParams]);

  const onSelectDateFilter = (eventKey) => {
    if (eventKey) {
      let datePreset = {
        label: Constants.DATERANGE_PRESETS.date_range_presets_definitions[eventKey].display_name,
        value: eventKey,
        filter_label: 'Created On'
      };
      setSelectedDateRange(datePreset);
      setShowErrorMessage(false);
      setIsDropdownOpen(false);
      updateFilter({ date_filter: { key: 'created_on', preset: datePreset.value } })
    }
  };

  const onSelectDateFilterProspect = (eventKey, filter) => {
    let datePreset = {
      label: Constants.DATERANGE_PRESETS.date_range_presets_definitions[eventKey].display_name,
      value: eventKey,
      filter_label: filter === 'created_on' ? 'Created On' : 'Enriched On'
    };

    if (filter === 'created_on') {
      setSelectedDateRange(datePreset);
    } else if (filter === 'enriched_on') {
      setEnrichedOnFilter(datePreset);
    }

    setShowErrorMessage(false);
    setIsDropdownOpen(false);

    // Use datePreset directly and include 'enriched_on' only if it exists
    setFilter((prevFilter) => {
      const updatedFilters = { ...prevFilter.filters };
      const dateFilterArray = [
        { key: 'created_on', preset: filter === 'created_on' ? datePreset.value : selectedDateRange?.value }
      ];

      // Add 'enriched_on' only if it exists and was updated
      if (filter === 'enriched_on' || enrichedOnFilter?.value) {
        dateFilterArray.push({
          key: 'enriched_on',
          preset: filter === 'enriched_on' ? datePreset.value : enrichedOnFilter?.value
        });
      }

      updatedFilters.date_filter = dateFilterArray;

      return {
        ...prevFilter,
        filters: updatedFilters,
        currentPageIndex: 0
      };
    });
  };

  const onSelectVerifiedFilter = (eventKey) => {
    if (eventKey) {
      let verifiedFilter = Constants.VERIFIED_TYPES[eventKey];
      verifiedFilter.filter_label = 'Status';
      setVerifiedListFilter(verifiedFilter);
      setShowErrorMessage(false);
      setIsDropdownOpen(false);
      updateFilter({ verified: verifiedFilter.value })
    }
  };

  const onSelectEfProcessedFilter = (eventKey) => {
    if (eventKey) {
      let processedFilter = Constants.EF_LIST_PROCESSED_TYPES[eventKey];
      processedFilter.filter_label = 'Status';
      setProcessedListFilter(processedFilter);
      setShowErrorMessage(false);
      setIsDropdownOpen(false);
      updateFilter({ processed: processedFilter.value })
    }
  }

  const onSelectProspectEnrichedStatusFilter = (eventKey) => {
    if (eventKey) {
      let enrichedStatusFilter = Constants.PROSPECT_ENRICHED_TYPES[eventKey];
      enrichedStatusFilter.filter_label = 'Status';
      setEnrichedStatusFilter(enrichedStatusFilter);
      setShowErrorMessage(false);
      setIsDropdownOpen(false);
      updateFilter({ enriched: enrichedStatusFilter.value })
    }
  }

  const onSelectProspectTypeFilter = (eventKey) => {
    if (eventKey) {
      let prospectTypeFilter = Constants.PROSPECT_PERSON_OR_COMPANY_TYPES[eventKey];
      prospectTypeFilter.filter_label = 'Type';
      setProspectTypeFilter(prospectTypeFilter);
      setShowErrorMessage(false);
      setIsDropdownOpen(false);
      updateFilter({ type: prospectTypeFilter.value })
    }
  }

  // Populate badges for selected filters
  const showLabels = () => {
    let filters = Constants.SERVICE_FILTERS[service.NAME];
    return filters.map((filter, index) => {
      const filterData = filterState[filter];
      if (filterData && filterData.value) {
        return (
          <Badge
            key={index}
            color="default"
            className="filter-badge"
          >
            {filterData.filter_label ? filterData.filter_label + ': ' : null}
            <span>{filterData.label}</span>
            <span
              className="close-icon"
              onClick={() => onRemoveFilter(filter)}
            >
            </span>
          </Badge>
        );
      }
      return null;
    });
  };

  const onRemoveDateFilterProspect = (filter) => {
    setFilter((prevFilter) => {
      const updatedFilters = { ...prevFilter.filters };

      // Filter out the removed filter from the date_filter array
      const updatedDateFilter = updatedFilters.date_filter.filter(item => item.key !== filter);

      // Update the filters with the modified date_filter array
      updatedFilters.date_filter = updatedDateFilter;

      return {
        ...prevFilter,
        filters: updatedFilters,
        currentPageIndex: 0
      };
    });
  };

  // Remove a specific filter
  const onRemoveFilter = (filter) => {
    let reset = { value: '', label: '' };
    switch (filter) {
      case 'selectedTypeFilter':
        setSelectedTypeFilter(reset);
        updateFilter({
          type: null,
        })
        break;
      case 'selectedDateRange':
        setSelectedDateRange(reset);
        updateFilter({
          date_filter: null,
        })
        break;
      case 'verifiedListFilter':
        setVerifiedListFilter(reset);
        updateFilter({
          verified: null,
          in_progress_status: null,
          processed: null
        })
        break;
      case 'enrichedOnFilter':
        setEnrichedOnFilter({})
        onRemoveDateFilterProspect('enriched_on')
        break
      case 'enrichedStatusFilter':
        setEnrichedStatusFilter(reset)
        updateFilter({enriched: null})
        break
      default:
        break;
    }
  };

  // Reset filters to initial state
  const resetFilters = () => {
    setSelectedTypeFilter(initialState.selectedTypeFilter);
    setSelectedDateRange(initialState.selectedDateRange);
    setVerifiedListFilter(initialState.verifiedListFilter);
    setShowErrorMessage(initialState.showErrorMessage);
    setEnrichedOnFilter({})
    setProspectTypeFilter(initialState.prospectTypeFilter)
    setEnrichedStatusFilter(initialState.enrichedStatusFilter)
    if (service.NAME === 'prospect') {
      updateFilter({
        date_filter: [{ key: 'created_on', preset: 'ps_last_7_days_including_today' }],
        enriched: 'in_progress',
        type: 'all'
      })
    }
    else {
      updateFilter({
        type: null,
        date_filter: { key: 'created_on', preset: 'ps_last_7_days_including_today' },
        verified: 'in_progress'
      })
    }
  };

  // Toggle dropdown open/close
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <>
      <div className="lists-filters">
        <div
          className="d-flex"
          ref={filterDropdownRef}
        >
          <div className="filter-comp">
            <Dropdown
              open={isDropdownOpen}
              onToggle={() => { }}
            >
              <Dropdown.Toggle onClick={toggleDropdown}>
                <i className="fa fa-filter"></i>
              </Dropdown.Toggle>
              {isDropdownOpen && (
                <Dropdown.Menu>
                  {
                    Constants.SERVICE_FILTERS[service.NAME].includes('verifiedListFilter') ? (
                      <MenuItem
                        className="filter-menu-item"
                        onSelect={onSelectVerifiedFilter}
                      >
                        Status
                        {Object.keys(Constants.VERIFIED_TYPES).map((key) => {
                          const { label, value } = Constants.VERIFIED_TYPES[key];
                          return (
                            <MenuItem
                              key={value}
                              className="filter-menu-item"
                              eventKey={value}
                            >
                              {label}
                            </MenuItem>
                          );
                        })}
                      </MenuItem>
                    ) : null
                  }
                  {
                    Constants.SERVICE_FILTERS[service.NAME].includes('processedListFilter') ? (
                      <MenuItem
                        className="filter-menu-item"
                        onSelect={onSelectEfProcessedFilter}
                      >
                        Status
                        {Object.keys(Constants.EF_LIST_PROCESSED_TYPES).map((key) => {
                          const { label, value } = Constants.EF_LIST_PROCESSED_TYPES[key];
                          return (
                            <MenuItem
                              key={value}
                              className="filter-menu-item"
                              eventKey={value}
                            >
                              {label}
                            </MenuItem>
                          );
                        })}
                      </MenuItem>
                    ) : null
                  }
                  {
                    Constants.SERVICE_FILTERS[service.NAME].includes('prospectTypeFilter') ? (
                      <MenuItem
                        className="filter-menu-item"
                        onSelect={onSelectProspectTypeFilter}
                      >
                        Type
                        {Object.keys(Constants.PROSPECT_PERSON_OR_COMPANY_TYPES).map((key) => {
                          const { label, value } = Constants.PROSPECT_PERSON_OR_COMPANY_TYPES[key];
                          return (
                            <MenuItem
                              key={value}
                              className="filter-menu-item"
                              eventKey={value}
                            >
                              {label}
                            </MenuItem>
                          );
                        })}
                      </MenuItem>
                    ) : null
                  }
                  {
                    Constants.SERVICE_FILTERS[service.NAME].includes('enrichedStatusFilter') ? (
                      <MenuItem
                        className="filter-menu-item"
                        onSelect={onSelectProspectEnrichedStatusFilter}
                      >
                        Status
                        {Object.keys(Constants.PROSPECT_ENRICHED_TYPES).map((key) => {
                          const { label, value } = Constants.PROSPECT_ENRICHED_TYPES[key];
                          return (
                            <MenuItem
                              key={value}
                              className="filter-menu-item"
                              eventKey={value}
                            >
                              {label}
                            </MenuItem>
                          );
                        })}
                      </MenuItem>
                    ) : null
                  }
                  {
                    Constants.SERVICE_FILTERS[service.NAME].includes('selectedDateRange') ? (
                      <MenuItem
                        className="filter-menu-item"
                        onSelect={service.NAME === 'prospect'
                          ? (eventKey) => onSelectDateFilterProspect(eventKey, 'created_on')
                          : onSelectDateFilter}
                      >
                        Created On
                        {Constants.DATERANGE_PRESETS.date_range_presets.map((preset) => (
                          <MenuItem
                            key={preset}
                            eventKey={Constants.DATERANGE_PRESETS.date_range_presets_definitions[preset].value}
                          >
                            {Constants.DATERANGE_PRESETS.date_range_presets_definitions[preset].display_name}
                          </MenuItem>
                        ))}
                      </MenuItem>
                    ) : null
                  }
                  {
                    Constants.SERVICE_FILTERS[service.NAME].includes('enrichedOnFilter') ? (
                      <MenuItem
                        className="filter-menu-item"
                        onSelect={(eventKey) => onSelectDateFilterProspect(eventKey, 'enriched_on')}
                      >
                        Enriched On
                        {Constants.DATERANGE_PRESETS.date_range_presets.map((preset) => (
                          <MenuItem
                            key={preset}
                            eventKey={Constants.DATERANGE_PRESETS.date_range_presets_definitions[preset].value}
                          >
                            {Constants.DATERANGE_PRESETS.date_range_presets_definitions[preset].display_name}
                          </MenuItem>
                        ))}
                      </MenuItem>
                    ) : null
                  }
                  {
                    Constants.SERVICE_FILTERS[service.NAME].includes('selectedTypeFilter') ? (
                      <MenuItem
                        className="filter-menu-item"
                      >
                        Source
                        {Object.keys(Constants.LIST_TYPES).map((list) => (
                          <MenuItem
                            key={list}
                            eventKey={Constants.LIST_TYPES[list].value}
                            onSelect={() => onSelectSource(Constants.LIST_TYPES[list].value)}
                          >
                            {Constants.LIST_TYPES[list].label}
                          </MenuItem>
                        ))}
                      </MenuItem>
                    ) : null
                  }
                </Dropdown.Menu>
              )}
            </Dropdown>
            <div className="list-selected-filters">{showLabels()}</div>
          </div>

        </div>
        {showErrorMessage && <p className="error-message">Please select default filters of date and source</p>}
      </div>
      <Button
        className="filter-reset-btn"
        onClick={resetFilters}
      >Reset
      </Button>
    </>

  );
};

export default Filters;
