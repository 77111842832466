import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import _ from 'lodash'
import { IoMdAdd } from 'react-icons/io';

import * as Constants from '../../helpers/constants'
import showToastNotification from '../widgets/toastify';
import { ApiHelper } from '../../helpers/apihelper';

import 'react-phone-number-input/style.css';
import './../../static/css/userlist.css'


const initialState = {
  showModal: false,
  email: '',
  phoneNumber: '',
  errors: {},
  isApiCallInProgress: false
}

const CreateUserModal = ({ getClearoutUsers, client }) => {
  const [state, setState] = useState(initialState)

  const handleEmailChange = (e) => {
    setState(prevState => ({ ...prevState, email: e.target.value }))
  };

  const validateEmail = (email) => {
    const emailRegex = Constants.REGEX.EMAIL_SYNTAX;
    const isValidEmail = emailRegex.test(email);

    if (!isValidEmail) {
      setState(prevState => ({
        ...prevState, errors: {
          emailAddress: 'Enter a valid email address',
        },
      }));

      setTimeout(() => {
        setState(prevState => ({
          ...prevState,
          errors: {
            emailAddress: null,
          },
        }));
      }, 5000);
    }

    return isValidEmail;
  };

  const handlePhoneNumberChange = (value) => {
    setState(prevState => ({ ...prevState, phoneNumber: value }))
  };

  const validateForm = () => {
    if (!isValidPhoneNumber(state.phoneNumber)) {
      showToastNotification({
        message: 'Please Enter a Valid Phone number',
        isSuccess: false,
      });
      return false
    }
    const isValidEmail = validateEmail(state.email);

    if (!isValidEmail) {
      return false
    }
    return true
  }

  const handleCreateUser = () => {
    if (validateForm()) {
      if (state.isApiCallInProgress) {
        return;
      }
      let requestBody = {
        email: state.email,
        phone: state.phoneNumber,
        client,
      }

      let body = _.pick(requestBody, ApiHelper(client, 'CREATE_USER').API_BODY)

      setState(prevState => ({ ...prevState, isApiCallInProgress: true }));
      ApiHelper(client, 'CREATE_USER').API(client, body)
        .then((response) => {
          if (response.status === Constants.CO_API.FAILED) {
            showToastNotification({
              message: response.message,
              isSuccess: false,
            })
          } else if (response.status === Constants.CO_API.SUCCESS) {
            showToastNotification({
              message: 'user created successfully',
              isSuccess: true,
            });
            handleCloseModal()
            getClearoutUsers();
          } else {
            showToastNotification({
              message: response.message,
              isSuccess: true,
            });
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          setState(prevState => ({ ...prevState, isApiCallInProgress: false }));
        });
    }
  };
  const handleShowModal = () => {
    setState(prevState => ({ ...prevState, showModal: true }));
  };
  const handleCloseModal = () => setState(initialState);

  return (
    <>
      <Button
        onClick={handleShowModal}
        variant='primary'
      >
        <IoMdAdd
          size={20}
          className='me-1'
        />
        Create User
      </Button>
      <Modal
        show={state.showModal}
        onHide={handleCloseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title className='fs-6 fw-bold'>Create User</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body-bg-color'>
          <Form>
            <div className='row'>
              <Form.Group
                className='d-flex'
              >
                <div className='col-md-3'>
                  <Form.Label className='w-100 py-2'>Email Address:</Form.Label>
                </div>
                <div className='col-md-9'>
                  <Form.Control
                    type="email"
                    placeholder="Enter Email Address"
                    value={state.email}
                    onChange={handleEmailChange}
                    isInvalid={!!state.errors.emailAddress}

                  />
                  <Form.Control.Feedback type="invalid">
                    {state.errors.emailAddress}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
            </div>
            <div className='row'>
              <Form.Group
                className='d-flex'
              >
                <div className='col-md-3'>
                  <Form.Label className='w-100 py-2'>Phone Number:</Form.Label>
                </div>
                <div className='col-md-9'>
                  <PhoneInput
                    placeholder="Enter phone number"
                    value={state.phoneNumber}
                    onChange={handlePhoneNumberChange}
                    maxLength={20}
                    defaultCountry="US"
                  />
                </div>
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-success"
            onClick={handleCreateUser}
            disabled={!state.email || !state.phoneNumber || state.isApiCallInProgress}
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateUserModal;
