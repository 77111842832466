import React, { useCallback, useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import _ from 'lodash'

import showToastNotification from './../../widgets/toastify'
import CustomCheckOrRadio from '../../widgets/custom_check'
import CustomDropdown from '../../widgets/custom_dropdown'
import { ApiHelper } from '../../../helpers/apihelper'

import * as Constants from './../../../helpers/constants'
import * as MiscellaneousConstants from './../helpers/constants'

const RADAR_ACTIONS = MiscellaneousConstants.RADAR_ACTIONS

const initialState = {
  status: false,
  allow_purchase_without_subscription: false,
  payment_initiated: _.find(RADAR_ACTIONS, { value: 'block_user' }),
  payment_failed: _.find(RADAR_ACTIONS, { value: 'block_user' })
}

const RadarSettingsModal = ({
  showModal,
  closeModal,
  client
}) => {

  const [state, setState] = useState(initialState)

  const getRadarSettings = useCallback(() => {

    let requestQueryParams = {
      key: 'all'
    }

    let queryParams = _.pick(requestQueryParams, ApiHelper(client, 'GET_SYSTEM_RADAR_SETTINGS').API_QUERY)

    ApiHelper(client, 'GET_SYSTEM_RADAR_SETTINGS').API(client, queryParams)
      .then((response) => {
        if (response.status === Constants.CO_API.FAILED) {
          showToastNotification({
            message: 'Failed to get radar settings',
            isSuccess: false,
          })
        } else {
          setState((prevState) => ({
            ...prevState,
            status: response.data.status,
            payment_failed: _.find(RADAR_ACTIONS, { value: response.data.payment_failed }),
            payment_initiated: _.find(RADAR_ACTIONS, { value: response.data.payment_initiated }),
            allow_purchase_without_subscription: response.data.allow_purchase_without_subscription,
          }))
        }
      })
      .catch((error) => {
        showToastNotification({
          message: error.message || 'Failed to get radar settings',
          isSuccess: false,
        })
        console.log(error)
      })
  }, [client])

  // mount
  useEffect(() => {
    getRadarSettings()
  }, [getRadarSettings])

  const onCheckboxValueChange = (e) => {
    let { name } = e.target

    setState((prevState) => ({
      ...prevState,
      [name]: !prevState[name]
    }))
  }

  const onDropdownSelect = (selectedOption, e) => {
    let { name } = e

    setState((prevState) => ({
      ...prevState,
      [name]: selectedOption
    }))
  }

  const onUpdateSettingsClicked = () => {
    let requestBody = {
      settings: {
        status: state.status,
        allow_purchase_without_subscription: state.allow_purchase_without_subscription,
        payment_initiated: state.payment_initiated.value,
        payment_failed: state.payment_failed.value
      }
    }

    let body = _.pick(requestBody, ApiHelper(client, 'UDPATE_SYSTEM_RADAR_SETTINGS').API_BODY)

    ApiHelper(client, 'UDPATE_SYSTEM_RADAR_SETTINGS').API(client, body)
      .then((response) => {
        if (response.status === Constants.CO_API.FAILED) {
          showToastNotification({
            message: 'Failed to get update radar settings',
            isSuccess: false,
          })
        } else {
          showToastNotification({
            message: 'Radar settings updated successfuly',
            isSuccess: true,
          })
        }
      })
      .catch((error) => {
        console.log(error)
        showToastNotification({
          message: error.message || 'Failed to get update radar settings',
          isSuccess: false,
        })
      })
  }
  return (
    <Modal
      show={showModal}
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title className='fs-6 fw-bold'>
          Update System Radar Settings
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='modal-body-bg-color'>
        <div className="product-is-recurring-check">
          <CustomCheckOrRadio
            type='checkbox'
            name='status'
            checked={state.status}
            onValueChange={onCheckboxValueChange}
          />
          <span>Global Radar Status</span>
        </div>
        <div className="product-is-recurring-check">
          <CustomCheckOrRadio
            type="checkbox"
            name='allow_purchase_without_subscription'
            checked={state.allow_purchase_without_subscription}
            onValueChange={onCheckboxValueChange}
          />
          <span>Allow Addon Purchase Without Active Subscription</span>
        </div>
        <div className='custom-product-name-dropdown'>
          <label>Payment Failure Action</label>
          <CustomDropdown
            name='payment_failed'
            options={RADAR_ACTIONS}
            placeholder={'Select payment failure action'}
            value={state.payment_failed}
            onSelectOption={onDropdownSelect}
            searchable={false}
          />
        </div>
        <div className='custom-product-name-dropdown'>
          <label>Payment Initiated Action</label>
          <CustomDropdown
            name='payment_initiated'
            options={RADAR_ACTIONS}
            placeholder={'Select payment initiated action'}
            value={state.payment_initiated}
            onSelectOption={onDropdownSelect}
            searchable={false}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={onUpdateSettingsClicked}
          variant='outline-primary'
        >
          Update Settings
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default RadarSettingsModal